import React, { useEffect, useState } from "react";
import {
  contractAddress,
  infura_RPC_URL_Polygon_Mainnet,
} from "../Constants/ContractAddress";
import { abi } from "../Constants/abi";
import Web3 from "web3";
import {
  loadingToast,
  successToast,
  errorToast,
} from "../helpers/alertsMessages";

export const TransactionContext = React.createContext();

let eth;

if (typeof window !== "undefined") {
  eth = window.ethereum;
}
const web3 = new Web3(infura_RPC_URL_Polygon_Mainnet);

const getEthereumContract = () => {
  let contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export const TransactionProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [makeUsVisibleContract, setMakeUsVisibleContract] = useState(
    getEthereumContract()
  );
  const [isSaleStarted, setIsSaleStarted] = useState(false);
  const [chainId, setChainId] = useState();
  /**
   * Checks if MetaMask is installed and an account is connected
   * @param {*} metamask Injected MetaMask code from the browser
   * @returns
   */
  const checkIfWalletIsConnected = async (metamask = eth) => {
    try {
      if (!metamask) {
        console.log("makeUsVisibleContract", makeUsVisibleContract);
        checkSaleStarted();
        return loadingToast("Please install metamask ");
      }
      const accounts = await metamask.request({ method: "eth_accounts" });
      setUserAndLoadContract(metamask, accounts);
    } catch (error) {
      console.error(error);
      throw new Error("No ethereum object.");
    }
  };

  /**
   * Prompts user to connect their MetaMask wallet
   * @param {*} metamask Injected MetaMask code from the browser
   */
  const connectWallet = async (metamask = eth) => {
    try {
      if (!metamask) return isLoading("Please install metamask ");
      const accounts = await metamask.request({
        method: "eth_requestAccounts",
      });
      setUserAndLoadContract(metamask, accounts);
      loadingToast("Your wallet is connecting !");
    } catch (error) {
      console.error(error);
      errorToast(`Please connect your wallet for minting`);
      // errorToast(error);
    }
  };

  async function setUserAndLoadContract(metamask, accounts) {
    try {
      if (!accounts.length) {
        connectWallet();
      }
      setCurrentAccount(accounts[0]);
      // validateChain(metamask);
      const contract = getEthereumContract();
      setMakeUsVisibleContract(contract);
    } catch (err) {
      console.error("Failed to set and load contract", err);
    }
  }

  async function validateChain(metamask) {
    const fetchChainId = await metamask.chainId;
    setChainId(fetchChainId == 0x89 ? true : false);

    if (!fetchChainId) {
      loadingToast("Please Install the metamask");
    }
    // Check chain id
    if (fetchChainId != 0x89) {
      loadingToast(
        "Please wait, while the metamask is connecting with Polygon Mainnet"
      );
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${Number(137).toString(16)}`,
              chainName: "Polygon Mainnet",
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              rpcUrls: ["https://polygon-rpc.com/"],
              blockExplorerUrls: ["https://polygonscan.com/"],
            },
          ],
        });
        window.location.reload();
      } catch (err) {
        errorToast(err?.message);
        console.error("fffff", err);
      }
    }
  }

  async function checkSaleStarted() {
    const new2 = await makeUsVisibleContract?.methods.sale().call();
    setIsSaleStarted(new2);
  }

  /**
   * Executes a transaction
   * @param {*} metamask Injected MetaMask code from the browser
   * @param {string} currentAccount Current user's address
   */
  const mintToken = async (noOfMinted = 1, metamask = eth) => {
    try {
      if (!metamask) return loadingToast("Please install metamask ");
      if (!currentAccount) {
        connectWallet();
      }
      validateChain(metamask);
      const price = await makeUsVisibleContract.methods.salePrice().call();
      if (isSaleStarted) {
        if (noOfMinted) {
          let finalPrice = price * noOfMinted;
          console.log("noOfMintedToken", noOfMinted, finalPrice);
          let parms = {
            to: contractAddress,
            from: currentAccount,
            data: makeUsVisibleContract.methods
              ?.mintToken(noOfMinted)
              .encodeABI(),
            value: Number(finalPrice).toString(16),
          };
          try {
            const txhash = await window?.ethereum?.request({
              method: "eth_sendTransaction",
              params: [parms],
            });
            console.log(txhash);
            successToast(`You have successfully minted ${noOfMinted} token`);
          } catch (err) {
            console.error("Failed to mint");
            errorToast(err?.message);
          }
        }
      } else {
        errorToast("Sale is not started yet");
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if(window.location.hash=== '#/make-us-visible')
  //   checkIfWalletIsConnected();
  // }, []);

  const ethEnabled = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then(handleAccountsChanged)
        .catch((err) => {
          console.error(err, "NOT CONNECTED");
        });

      window.ethereum.on("accountsChanged", handleAccountsChanged);
      function handleAccountsChanged(accounts) {
        if (accounts.length === 0) {
          console.log("Please connect to MetaMask.");
        } else if (accounts[0] !== currentAccount) {
          setCurrentAccount(accounts[0]);
          console.log(accounts);
        }
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    checkSaleStarted();

    ethEnabled();
  }, [makeUsVisibleContract]);

  return (
    <TransactionContext.Provider
      value={{
        connectWallet,
        currentAccount,
        mintToken,
        isLoading,
        isSaleStarted,
        chainId,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};
