import React from "react";
import Sheet from "react-modal-sheet";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { DEFAULT_BACK_IMAGE_URL } from "../Constants/Constant";
import "../components/DiscoverLocation.css";
import "../components/EventComponent.css";
import { getArtistName } from "../helpers/util";
import EventDetailScreen from "../pages/EventDetailScreen";
function MobileEventCard(props) {
  const location = useLocation()
  const history = useHistory()
  const [isOpen, setOpen] = React.useState(false);

  const Styled_Spring = {
    stiffness: 300,
    damping: 45,
    mass: 0.2,
  };

  const handleButton = () => {
    location.pathname === "/2023-AiOP-DRESS" ? 
    history.push(location.pathname + "/" + props.data.id) : 
    setOpen(true);
    // props.history.push({ pathname: '/event', state: { data: props.data } });
  };
  const handleDateFormat = (timings, startDate, endDate) => {
    let date = timings.split(" ");
    date[0] = date[0].slice(0, 3);
    date[3] = date[3].slice(0, 3);
    if (startDate === endDate) {
      return date[3] + " " + date[4] + " " + date[5];
    }
    return date.join(" ");
  };
  return (
    <>
      <div className="w-100">
        <Sheet
          id="sheet"
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          springConfig={Styled_Spring}
          initialSnap={0}
          rootId="mainPage"
        >
          <Sheet.Container>
            <Sheet.Header />

            <Sheet.Content disableDrag={true}>
              <EventDetailScreen eventDetails={props.data} />
            </Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop />
        </Sheet>
      </div>

      <div
        onClick={handleButton}
        style={{ border: "3px solid black", boxShadow: "12px 8px 12px" }}
        className="w-100 ml-2 display-direction-column bg-white p-2 br--12"
      >
        <div className="w-100  ta-start ">
          {props.data.images && props.data.images && (
            <ImageComponent
              url={
                props.data.images.length > 0
                  ? props.data.images[0].image_file
                  : DEFAULT_BACK_IMAGE_URL
              }
            />
          )}
        </div>

        <div style={{ height: "100px" }} className="bg-white px-2 py-1 mt-2">
          <p className="font-weight-bold dot-3 color-black exhibition-name  mb-2 ta-start">
            {props.data.name}
          </p>
          {!props.onlyArtistName? (
            props.data?.artist?.[0] && (
              <div className="mb-1 event-desc-1-line ta-start">
                <p>
                  By
                  <span className="event-card-artist">
                    {" "}
                    {getArtistName(
                      props?.data?.artist[0]?.first_name,
                      props?.data?.artist[0]?.last_name
                    )}
                  </span>
                  {props.data?.artist?.[1] && " & "}
                  <span className="event-card-artist">
                    {props.data?.artist?.[1] &&
                      getArtistName(
                        props?.data?.artist[1]?.first_name,
                        props?.data?.artist[1]?.last_name
                      )}
                  </span>
                </p>
              </div>
            )
          ) : (
            <>
              <p className="mb-1 event-desc-1-line ta-start ">
                {props.data.short_address}
              </p>
              <p className="mb-0 event-desc-1-line ta-start ">
                {handleDateFormat(
                  props.data.timings,
                  props.data.start_date,
                  props.data.end_date
                )}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}

function ImageComponent(props) {
  return (
    <div className="flex-direction-row">
      <img
        loading="lazy"
        className="image-container-2 object-fit-image br--12"
        src={props.url}
        alt="props"
      />
    </div>
  );
}

export default withRouter(MobileEventCard);
