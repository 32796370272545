import '../App.css'
import Spinner from 'react-bootstrap/Spinner'

export function isAuthenticated() {
  const token = localStorage.getItem('token');
  if (token && token !== 'undefined' && token !== 'null' && token !== '' && typeof token !== undefined) {
    return true;
  } else {
    return false;
  }
 
}

export function numberOfEvents(num) {
  if(num === 1){
    return num + " Current & Upcoming Event"
  }
  else {
    return num + " Current & Upcoming Events"
  }
}


export function Loader(){
  return(
    <div className='loader-container'>
      <Spinner animation="border" variant="primary" />
    </div>
  )
}

export function SmallLoader(){
  return(
    <div className='small_container'>
      <Spinner animation="border" variant="primary" />
    </div>
  )
}

export function getQueryParamAsBoolean(query, key) {
  const value = query.get(key);

  if (value === null) {
    return true;
  }

  return value === "false";
}