import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams, withRouter } from 'react-router-dom';

import Map from '../components/MapForEvent';
import SliderComponent from '../components/SliderComponent';
import { mobileCheck } from '../helpers/util';
import EventCardDetails from './EventCardDetails';

import '../App.css';
import '../components/DiscoverLocation.css';
import './EventPage.css';

import { apiRoutes, baseURL } from '../api/apiRoutes';
import { LATITUDE, LONGITUDE } from '../Constants/Constant';

import BackBtn from '../assets/icons/BackBtn.svg';

function NewEventDetailsPage(props) {
    // var locationId;
    const history = useHistory();

    const apiBaseURL = baseURL + apiRoutes.EVENTS;
    let { cityId, path } = useParams();

    let todayDate = new Date();

    const [cid, setCid] = useState(216);
    const [isOpen, setOpen] = useState(false);

    // const [cname, setCname] = useState("");
    const [eid, setEid] = useState(0);
    const [isLearnMore, setLearnMore] = useState(false);
    const [isDiscoverLocation, setDiscoverLocation] = useState(false);
    const [mapZoomLevel, setMapZoomLevel] = useState(10);
    const [currentlyViewingLocationID, setCurrentlyViewingLocationID] =
        useState(null);
    const [selectedTreeId, setSelectedTreeId] = useState(0);
    const [state, setState] = useState({
        isCollapsible: false,
        ArtworkImage: '',
        ArtworkTitle: '',
        ArtworkDesc: '',
        ArtworkAudio: '',
        currentLocation: '',
        latitude: LATITUDE,
        longitude: LONGITUDE,
        data: [
            {
                type: 'exhibition',
                lat: undefined, //When Not Allowed for location , no Marker is visible
                lon: undefined,
            },
        ],
        collective_slug_url: `${apiBaseURL + cityId}`,
    });

    const isCurrentDeviceMobile = mobileCheck();

    const handleArtworkImage = (image, title, desc, audio) => {
        setState((prevState) => ({
            ...prevState,
            ArtworkImage: image,
            ArtworkTitle: title,
            ArtworkDesc: desc,
            ArtworkAudio: audio,
        }));
    };

    const handleDiscoverLocation = (type) => {
        setDiscoverLocation(!type);
    };

    let url = state.collective_slug_url;

    const [allEventsListData, setAllEventsListData] = useState([]);

    async function getData(url) {
        const res = await fetch(url);
        const json = await res.json();
        setAllEventsListData([json]);
    }
    useEffect(() => {
        getData(url);
        if (isCurrentDeviceMobile) {
            setOpen(true);
        }
    }, []);

    const [apiData, setApiData] = useState('');

    let cityUrl = apiBaseURL + cityId;

    useEffect(() => {
        async function getData() {
            const res = await fetch(cityUrl);
            const json = await res.json();
            setApiData(json);
        }
        getData();
        // history.push({ pathname: data.id })
    }, []);

    useEffect(() => {
        props.setIsNav(false);
    }, []);

    return (
        <div>
            <Helmet>
                <title>{apiData.name ?? 'Art Event - ' + 'Pollinate Event'}</title>
            </Helmet>
            <div
                onClick={() =>
                    history.push(`/${path}?eventId=${cityId}`)
                }
                className='text-white  p-2 lg-hidden pointer sidebar-fixed-top '
            >
                <img
                    src={BackBtn}
                    className='mobile-back-btn-for-art-event'
                    alt='Back Button'
                />
            </div>
            <div className='row no-gutters bg-black  '>
                <div className='w-100 sm-hidden display-direction-row_main bg-black col-md-4 h-90vh overflow-y-scroll '>
                    <div id='list-container' className='overflow-auto'>
                        <div
                            onClick={() =>
                                history.push(`/${path}?eventId=${cityId}`)
                            }
                            className='px-4 d-lg-flex pointer align-items-center d-none pb-3 w-100 sidebar-fixed-top'
                            style={{ marginTop: '-4em' }}
                        >
                            <img src={BackBtn} alt='Back Button' />
                            <p className='event-series-text back-btn-chevron mb-1 ml-3 lg-show '>
                                Back
                            </p>
                        </div>
                        <div className='px-5 display-rows'>
                            <EventCardDetails apiData={apiData} />
                        </div>
                    </div>
                </div>

                <div className='col-md-8  overflow-y-scroll z-3'>
                    <Map
                        cid={cid}
                        eventsData={allEventsListData}
                        eventsDataloading={false}
                        eid={eid}
                        todayDate={todayDate}
                        latitude={state.latitude}
                        isLearnMore={isLearnMore}
                        setLearnMore={setLearnMore}
                        setCurrentlyViewingLocationID={(id) =>
                            setCurrentlyViewingLocationID(id)
                        }
                        zoomIn={(_) => setMapZoomLevel(18)}
                        zoomOut={(_) => setMapZoomLevel(12)}
                        longitude={state.longitude}
                        isCollapsible={state.isCollapsible}
                        collective_slug_url={state.collective_slug_url}
                        ArtworkAudio={state.ArtworkAudio}
                        ArtworkTitle={state.ArtworkTitle}
                        ArtworkDesc={state.ArtworkDesc}
                        ArtworkImage={state.ArtworkImage}
                        handleArtworkImage={handleArtworkImage}
                        isDiscoverLocation={isDiscoverLocation}
                        handleDiscoverLocation={handleDiscoverLocation}
                        onCustomClick={(id) => setEid(id)}
                        setEid={setEid}
                        zoomLevel={mapZoomLevel}
                        setSelectedTreeId={setSelectedTreeId}
                        currentlyViewingLocationID={currentlyViewingLocationID}
                        location_data={state.data}
                    />
                </div>

                <SliderComponent
                    eventDetail={apiData}
                    isOpen={isOpen}
                    setOpen={setOpen}
                />
            </div>
        </div >
    );
}

export default withRouter(NewEventDetailsPage);
