import React, { useState, useRef, useEffect } from "react";
import "../components/DiscoverLocation.css";
import "../components/EventComponent.css";
import { DEFAULT_BACK_IMAGE_URL } from "../Constants/Constant";
// import EventsCardDetails from "../pages/EventCardDetails";
import { useParams } from "react-router-dom";
import { mobileCheck } from "../helpers/util";
import Sheet from "react-modal-sheet";
import RedArrow from "../assets/icons/RedArrow.svg";

function EventsCardSidebar({ eventDetail, history, firstElementRef }) {
  const isCurrentDeviceMobile = mobileCheck();
  return (
    <DesktopCard
      eventDetail={eventDetail}
      history={history}
      isCurrentDeviceMobile={isCurrentDeviceMobile}
      firstElementRef={firstElementRef}
    />
  );
}

export default EventsCardSidebar;

const DesktopCard = ({ eventDetail, history, isCurrentDeviceMobile, firstElementRef }) => {
  const { id, cityName, filterType, cityId } = useParams();
  const ref = useRef(eventDetail.id)

  useEffect(() => {
    if (cityId == eventDetail.id && !firstElementRef) {
      ref.current.scrollIntoView({ block: 'center', inline: "center" });
    }
  }, [eventDetail, cityId]);

  const [isOpen, setOpen] = useState(false);

  return (
    <div ref={ref} >
      <div
        className=" w-100 event-cards-desktop bg-white"
        style={{
          borderRight: `${cityId == eventDetail.id ? (isCurrentDeviceMobile ? "" : "12px solid #e0f752") : ""
            }`,
          borderTop: `${cityId == eventDetail.id ? (isCurrentDeviceMobile ? "6px solid #e0f752" : "") : ""
            }`
        }}
        onClick={() =>
          history.push({
            // TODO: Fix this with regex .replace(regex ,'-')
            pathname: `/${filterType}/${cityName}/${id}/${eventDetail.name.replaceAll(
              "/",
              "_"
            )}/${eventDetail.id}/${eventDetail.Page_no}`,
          })
        }

      >
        <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>{<div>
              <div className=" ta-start">
                {eventDetail.images &&
                  eventDetail.images.map((item, id) => (
                    <div className="w-100" key={id}>
                      <img
                        className="image-container  m-auto br--12 object-fit-image"
                        src={item.image_file}
                        alt="artwork"
                      />
                    </div>
                  ))}
              </div>
              <div className="w-100 mt-4 ">
                <div className="w-100 d-flex justify-content-between">
                  <div className="w-60 d-flex">
                    <p className="event-title ta-start mb-2">{eventDetail.name}</p>
                  </div>
                </div>
              </div>

              {eventDetail.address && (
                <div>
                  <a
                    href={`https://www.google.com/maps/place/$q=${eventDetail.address}&ll=${eventDetail.lat},${eventDetail.lon}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="display-flex mt-3">


                      <p className="location ta-start" style={{ marginLeft: "5px" }}>
                        {eventDetail.address}
                      </p>
                    </div>
                  </a>
                </div>
              )}
              <p className="event-date ta-start mt-1 ">{eventDetail.timings}</p>

              {eventDetail.description && (
                <>
                  <p className="event-desc-heading mb-1 mt-4 ta-start">Description</p>
                  <p
                    className="event-desc ta-start mb-4"
                    dangerouslySetInnerHTML={{ __html: eventDetail.description }}
                  />
                </>
              )}
              {eventDetail?.website && (
                <a href={eventDetail.website} target="_blank" rel="noreferrer">
                  <div className="d-flex">
                    <p className="view-website mb-1 mr-1 mt-1">Learn more</p>
                    <img src={RedArrow} width="20px" alt="data" />
                  </div>
                </a>
              )}
            </div>}</Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop />
        </Sheet>
        <div></div>
        <div className="flex-direction-row ta-start">
          <img
            className="image-container-2 object-fit-image br--12"
            src={
              eventDetail?.images[0]?.image_file.length > 0
                ? eventDetail?.images[0]?.image_file
                : DEFAULT_BACK_IMAGE_URL
            }
            alt={eventDetail.name}
          />
        </div>
        <div className="ml-2 py-1 display-direction-column w-100 content-between">
          <div>
            <p className="dot-3 color-black exhibition-name  mb-2 ta-start">
              {eventDetail.name}
            </p>
            <p className="mb-1 event-card-address ta-start">
              {eventDetail.address}
            </p>
            <div>
              <span className=" event-card-date-sm ta-start">
                {eventDetail.timings.split("|")[0]}
              </span>
            </div>
            <div>
              <span className=" event-card-date ta-start">
                {eventDetail.timings.split("|")[0]}
              </span>
            </div>
          </div>

          {eventDetail.timings.split("|")[1] && (
            <div className="event-card-time">
              <span>{eventDetail.timings.split("|")[1]}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
