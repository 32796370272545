import React, { useEffect, useState } from "react";
import "../components/DiscoverLocation.css";
import { DEFAULT_IMAGE_URL, DEFAULT_AUDIO_URL } from "../Constants/Constant";
import Modal from "react-modal";
import ImageCarousel from "../components/common/Carousel";
const customStyles = {
  content: {
    background: "none",
    border: "none",
    top: "50%",
    left: "50%",
    right: "auto",
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "0px",
    paddingTop: "0px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ArtworkPopUp(props) {
  const [audio] = useState(
    new Audio(props.ArtworkAudio ? props.ArtworkAudio : DEFAULT_AUDIO_URL)
  );
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);
  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);
  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);
  const handleClosePopup = async () => {
    await setPlaying(false);
    props.handleArtworkImage("");
  };


  const AudioPlayer = () => {
    return (
      <div>
        {props.ArtworkAudio && (
          <div className='artwork-audio-popup'>
            <span onClick={toggle}>
              {!playing ? (
                <svg
                  style={{ paddingLeft: "8px" }}
                  width='48'
                  height='48'
                  viewBox='0 0 30 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M0 0V40L30 20L0 0Z' fill='white' />
                </svg>
              ) : (
                <svg
                  width='27'
                  height='32'
                  viewBox='0 0 27 32'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M0 32H9V0H0V32ZM18 0V32H27V0H18Z' fill='white' />
                </svg>
              )}
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Modal
        isOpen={true}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={() => handleClosePopup()}
      >
        <div>
          <div
            onClick={() => handleClosePopup()}
            style={{maxWidth:"100vw"}}
            className='d-flex justify-content-end mr-2 pointer'
          >
            <span>
              <svg
                width='17'
                height='25'
                viewBox='0 0 17 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M16.0427 1.41L14.427 0L8.02135 5.59L1.61573 0L0 1.41L6.40562 7L0 12.59L1.61573 14L8.02135 8.41L14.427 14L16.0427 12.59L9.63708 7L16.0427 1.41Z'
                  fill='black'
                />
              </svg>
            </span>
          </div>
          <div style={{ position: "relative" }}>
            <AudioPlayer />
            {props.ArtworkAudio ? (
              <img
                className='artwork-image-container'
                alt=''
                src={
                  props.ArtworkImage
                   
                }
              />
            ) : (
              <ImageCarousel imgArray={props.imgArray} />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ArtworkPopUp;
