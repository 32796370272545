import React from "react";
import "../components/DiscoverLocation.css";
import PollinateLogo from "../assets/icons/Pollinate-White-Full-Logo.svg";

import { withRouter } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import "./Navbar.css";

function NavbarComponent(props) {
  const navigateToCreateEvent = () => {
    localStorage.removeItem("EventId");
    props.history.push("/createEvent");
  };
  return (
    <div className={!props.isCreateEventbtnVisible ?"custom-navbar-for-create-event-screen" : "custom-navbar"}>
      { props.isNav && (
        <Navbar bg="dark" variant="dark" expand="lg" className="navbar fixed-top nav-height">
          <Container fluid className="d-flex justify-content-between">
            <a href="/">
              <img
                className="navbar-brand"
                src={PollinateLogo}
                alt="Pollinate Logo"
                width={window.innerWidth <= 480 ? "130px" : undefined}
              />
            </a>
            <div className="align-self-center">
              {props.isCreateEventbtnVisible && (
                <button
                  onClick={navigateToCreateEvent}
                  className="create-event-btn"
                >
                  Submit New Event
                </button>
              )}
            </div>
          </Container>
        </Navbar>
      )}
    </div>
  );
}
export default withRouter(NavbarComponent);
