import React, { Suspense, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../App.css";
// import EventListComponent from "../components/EventListComponent";
// import FullScreenImageComponent from "../components/FullScreenImageComponent";
import { useParams, withRouter } from "react-router-dom";
import { LATITUDE, LONGITUDE } from "../Constants/Constant";
import "../components/DiscoverLocation.css";
import Map from "../components/MapComponent";

import { Helmet } from "react-helmet";
import { apiRoutes, baseURL } from "../api/apiRoutes";
import EventsListSidebar from "../components/EventsListSidebar";
import { Loader } from "../helpers/helpers";
import useQuery from "../hooks/useQuery";

import BackBtn from "../assets/icons/BackBtn.svg";

// var locationId;

const apiBaseURL = baseURL + apiRoutes.EVENTS;

function EventsMapAndList(props) {
  let locationId;
  let query = useQuery().get("name");
  let todayDate = new Date();
  let history = useHistory();

  let { id, filterType, Page_no } = useParams();

  const [cid, setCid] = useState(id ? id : 198);
  // const [cname, setCname] = useState("");
  const [eid, setEid] = useState(0);
  const [isLearnMore, setLearnMore] = useState(false);
  const [fullScreenStatus, setFullScreenStatus] = useState(false);
  const [fullScreenPic, setFullScreenPic] = useState("");
  const [isDiscoverLocation, setDiscoverLocation] = useState(false);
  const [mapZoomLevel, setMapZoomLevel] = useState(8);
  const [currentlyViewingLocationID, setCurrentlyViewingLocationID] =
    useState(null);
  const [selectedTreeId, setSelectedTreeId] = useState(0);
  const [state, setState] = useState({
    isCollapsible: false,
    ArtworkImage: "",
    ArtworkTitle: "",
    ArtworkDesc: "",
    ArtworkAudio: "",
    currentLocation: "",
    latitude: LATITUDE,
    longitude: LONGITUDE,
    data: [
      {
        type: "exhibition",
        lat: undefined, //When Not Allowed for location , no Marker is visible
        lon: undefined,
      },
    ],
    collective_slug_url:
      filterType === "city"
        ? `${apiBaseURL + "?" + apiRoutes.CITY_ID + "=" + id + "&current=true"}`
        : filterType === "city-past-events"
        ? `${apiBaseURL + "?" + apiRoutes.CITY_ID + "=" + id}`
        : filterType === "past-events"
        ? `${apiBaseURL + "?" + apiRoutes.COLLECTIVE_ID + "=" + cid}`
        : `${
            apiBaseURL +
            "?" +
            apiRoutes.COLLECTIVE_ID +
            "=" +
            cid +
            "&current=true"
          }`,
  });

  const handleFullScreenImage = (pic) => {
    setFullScreenStatus(!fullScreenStatus);
    setFullScreenPic(pic);
  };

  // const handleOnClick = (id, name) => {
  //   console.log("IMAGE", id, name);
  //   setCid(id);
  //   setCname(name);
  //   setState((prevState) => ({
  //     ...prevState,
  //     collective_slug_url: `${process.env.REACT_APP_API_URL}${
  //       process.env.REACT_APP_COLLECTIVE_SLUG
  //     }${id > 0 ? "&collective_id=" : ""}${id > 0 ? id : ""}`,
  //   }));
  // };

  // TODO: remove unused code
  const handleCurrentLocation = (lat, long) => {
    setState((prevState) => ({
      ...prevState,
      latitude: lat,
      longitude: long,
      collective_slug_url:
        process.env.REACT_APP_API_URL +
        "experiencemap/?latmin=" +
        (lat - 0.05) +
        "&latmax=" +
        (lat + 0.05) +
        "&lonmin=" +
        (long - 0.05) +
        "&lonmax=" +
        (long + 0.05) +
        "&studio=false&exhibition=true&is_collective=false",
    }));
  };

  const handleArtworkImage = (image, title, desc, audio) => {
    setState((prevState) => ({
      ...prevState,
      ArtworkImage: image,
      ArtworkTitle: title,
      ArtworkDesc: desc,
      ArtworkAudio: audio,
    }));
  };

  const handleDiscoverLocation = (type) => {
    setDiscoverLocation(!type);
  };

  //use for get current location
  // useEffect(() => {
  //   isIOS() ? getUserCurrentLocation("granted") : handlePermission();
  //   return () => {
  //     navigator.geolocation.clearWatch(locationId);
  //   };
  // }, []);

  // const options = {
  //   enableHighAccuracy: false,
  //   timeout: DEFAULT_TIMER,
  //   maximumAge: 0,
  // };

  // function successCallBack(pos) {
  //   setState((prevState) => ({
  //     ...prevState,
  //     latitude: pos.coords.latitude,
  //     longitude: pos.coords.longitude,
  //     data: [
  //       {
  //         type: "exhibition",
  //         lat: pos.coords.latitude,
  //         lon: pos.coords.longitude,
  //       },
  //     ],
  //   }));
  // }

  // function errorCallBack(err) {
  //   console.warn("LOCATION ERROR(" + err.code + "): " + err.message);
  // }

  // function handlePermission() {
  //   try {
  //     navigator.permissions.query({ name: "geolocation" }).then((result) => {
  //       getUserCurrentLocation(result.state);
  //       result.onchange = () => {
  //         getUserCurrentLocation(result.state);
  //       };
  //     });
  //   } catch (e) {
  //     console.log("unable to get geolocation", e);
  //   }
  // }

  // const getUserCurrentLocation = (status) => {
  //   try {
  //     if (status === "granted") {
  //       locationId = navigator.geolocation.watchPosition(
  //         successCallBack,
  //         errorCallBack,
  //         options
  //       );
  //     }
  //   } catch (e) {
  //     console.log("unable to get location", e);
  //   }
  // };

  let url = state.collective_slug_url;
  const [eventsListDataResult, setEventsListDataResult] = useState([]);
  const [allEventsListData, setAllEventsListData] = useState("");
  // const [currentPageApiUrl, setCurrentPageApiUrl] = useState()
  const [firstElementRef, setFirstElementRef] = useState(false);

  async function getData(url) {
    // url && setCurrentPageApiUrl(url);
    const res = await fetch(url);
    const json = await res.json();
    json.results.map((eventDetails) => {
      return Object.assign(eventDetails, {
        Page_no: url.split("&")[1] === undefined ? "page=1" : url.split("&")[1],
        next: json.next,
        previous: json.previous,
      });
    });
    // console.log("json.results",json.results)
    setAllEventsListData(json);
    firstElementRef
      ? setEventsListDataResult((eventsListDataResult) => [
          ...json.results,
          ...eventsListDataResult,
        ])
      : setEventsListDataResult((eventsListDataResult) => [
          ...eventsListDataResult,
          ...json.results,
        ]);
  }
  useEffect(() => {
    Page_no ? getData(url + "&" + Page_no) : getData(url);
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div>
        {window === window.parent && (
          <div
            onClick={() =>
              history.push({
                pathname: `/`,
              })
            }
            className=" text-white z-20 p-2 lg-hidden pointer w-37 sidebar-fixed-top"
          >
            <img
              src={BackBtn}
              className="mobile-back-btn-for-art-event"
              alt="Back Button"
            />
          </div>
        )}
        <div className="w-100 display-direction-row_main overflow-hidden">
          <Helmet>
            <title>Pollinate Arts</title>
          </Helmet>

          <div
            id="list-container"
            className="xs-100 w-37 h-60 card-list-color-and-height overflow-auto"
          >
            {eventsListDataResult && allEventsListData && (
              <EventsListSidebar
                eventsListDataResult={eventsListDataResult}
                allEventsListData={allEventsListData}
                getData={getData}
                history={props.history}
                // currentUrl={currentPageApiUrl}
                setFirstElementRef={setFirstElementRef}
                firstElementRef={firstElementRef}
              />
            )}
          </div>
          <div className="xs-100 w-100 z-3">
            <Map
              cid={cid}
              eventsData={eventsListDataResult}
              eventsDataloading={false}
              eid={eid}
              todayDate={todayDate}
              latitude={state.latitude}
              isLearnMore={isLearnMore}
              setLearnMore={setLearnMore}
              setCurrentlyViewingLocationID={(id) =>
                setCurrentlyViewingLocationID(id)
              }
              zoomIn={(_) => setMapZoomLevel(19)}
              zoomOut={(_) => setMapZoomLevel(12)}
              longitude={state.longitude}
              isCollapsible={state.isCollapsible}
              handleCurrentLocation={handleCurrentLocation}
              collective_slug_url={state.collective_slug_url}
              ArtworkAudio={state.ArtworkAudio}
              ArtworkTitle={state.ArtworkTitle}
              ArtworkDesc={state.ArtworkDesc}
              ArtworkImage={state.ArtworkImage}
              handleArtworkImage={handleArtworkImage}
              isDiscoverLocation={isDiscoverLocation}
              handleDiscoverLocation={handleDiscoverLocation}
              onCustomClick={(id) => setEid(id)}
              handleFullScreenImage={handleFullScreenImage}
              setEid={setEid}
              zoomLevel={mapZoomLevel}
              setSelectedTreeId={setSelectedTreeId}
              currentlyViewingLocationID={currentlyViewingLocationID}
              location_data={state.data}
            />
          </div>
          {/* {fullScreenStatus && (
        <div className="full-image_container">
          <FullScreenImageComponent
            handleFullScreenImage={handleFullScreenImage}
            image={fullScreenPic}
          />
        </div>
      )} */}
        </div>
      </div>
    </Suspense>
  );
}

export default withRouter(EventsMapAndList);
