/**
 * Makes a GET request to the api at a supplied url.
 * It can determine if the browser is authenticated or not.
 * Some API calls don't require authentication.
 */

import { useEffect, useState } from 'react';
// import { isAuthenticated } from '../helpers/helpers';

function useGetFetch(url, mutatorFn) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  async function fetchGet() {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Token ${
          // isAuthenticated()
          //   ? localStorage.getItem('token')
          //   : 
            process.env.REACT_APP_API_TOKEN
        }`,
      },
    });
    if (res.status >= 400) {
      setData(404);
    } else {
      const json = await res.json();
      if (mutatorFn) {
        setData(mutatorFn(json));
        setLoading(false);
        return;
      }
      setData(json);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchGet();
  }, [url]);
  return [data, loading];
}
export { useGetFetch };
