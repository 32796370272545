import React from 'react';
function Tabs({ TabValues, state, setState }) {
  const onRadioChange = (type) => {
    setState((prevState) => ({
      ...prevState,
      filter: type,
    }));
  };
  return (
    <div>
      <ul className='tabs mb-0 '>
        {TabValues.map((TabValue) => (
          <li key={TabValue.value} className='mr-3  mt-2 '>
            <input
              type='radio'
              id={TabValue.value}
              checked={state.filter === TabValue.value}
              name='filter'
              value={TabValue.value}
              onChange={() => onRadioChange(TabValue.value)}
            />
            <label className='pointer' htmlFor={TabValue.value}>{TabValue.label}</label>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Tabs;
