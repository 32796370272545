import React, { useEffect, useRef, useState } from "react";
import "./EventHomepage.css";
import uploadImage from "../assets/icons/upload_image.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, withRouter } from "react-router-dom";
import BackBtn from "../assets/icons/BackBtn.svg";
import { Form, Row, Col } from "react-bootstrap";
import Sheet from "react-modal-sheet";
import Autocomplete from "react-google-autocomplete";
import EventDays from "../components/EventDays";
import { CONNECTION_ERROR, DaysOfWeek } from "../Constant";

import ImageUploader from "../components/Form/ImageUploader";
import ImagePreviewer from "../components/Form/ImagePreviewer";
import { getObjectDetails, uploadToS3 } from "../api/apihelpers";
import { isConnection, isNotEmpty } from "../helpers/util";
import {
  API_BASE_URL,
  API_BASE_TOKEN,
  // GOOGLE_PLACES_API_KEY,
  RESPONSE_ERROR,
} from "../api/apiConstants";
import { apiRoutes } from "../api/apiRoutes";
import { Loader } from "../helpers/helpers";
import TextError from "../components/Form/TextError";
// import InviteArtistComponent from "../components/Form/InviteArtistComponent";
import FormModal from "../components/Form/FormModal";
import { Helmet } from "react-helmet";

function CreateEventScreen(props) {
  const [validated, setValidated] = useState(false);
  const [continueBtn, setContinueBtn] = useState(false);
  const [error, setError] = useState("");
  // const isFirstRender = useRef(true);
  const [verified, setVerified] = useState(false);
  // const [inviteArtistComponent, setInviteArtistComponent] = useState(false);
  const [dateError, setDateError] = useState("");
  // const [timeError, setTimeError] = useState("");
  const [isEditEvent, setIsEditEvent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({});
  const [checked, setChecked] = useState(false);
  // const [back, setBack] = useState(false);
  const [isValidWebsite, setIsValidWebsite] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [noValidAddress, setNoValidAddress] = useState("");
  const [inputValue, setInputValue] = useState({
    name: "",
    host_name: "",
    host_email: "",
    description: "",
    website: "",
  });
  const [timings, setTimings] = useState({
    startDate: "",
    endDate: "",
    // startTime: "23:59",
    // endTime: "23:59",
  });
  const [imagePreview, setimagePreview] = useState(null);
  const [imageId, setimageId] = useState(null);
  // const [selectedArtist, setSelectedArtist] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [newAddedImages, setNewAddedImages] = useState([]);
  const [mutableArray, setmutableArray] = useState(Array(6).fill(null));
  const [previousImages, setPreviousImages] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  // const [eventArtists, setEventArtists] = useState([]);
  const [popUp, setPopUp] = useState(false);

  const repeatDays = [];
  const numberOfImageUploaders = 6;
  const Styled_Spring = {
    stiffness: 300,
    damping: 45,
    mass: 0.2,
  };
  // const businessHours = [];
  const handleDoneClick = () => {
    setSelectedDays((selectedDays) => {
      selectedDays?.sort(function (element1, element2) {
        return element1 - element2;
      });
      return [...selectedDays];
    });
    // repeatDays.splice(0, repeatDays.length, selectedDays);
  };

  (function pushIntoWeekDaysArray() {
    return selectedDays.forEach(function (dayNumber) {
      repeatDays.push(DaysOfWeek[dayNumber].slice(0, 3));
    });
  })();

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  const emailValidation = (value) => {
    const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regex.test(value);
  };

  const handleStateChange = (e) => {
    const { id, value } = e.target;
    if (id === "website") {
      setIsValidWebsite(urlPatternValidation(value));
    }
    if (id === "host_email") {
      setIsValidEmail(emailValidation(value));
    }
    setInputValue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setError("");
  };

  const handleTimings = (e, key) => {
    setContinueBtn(false);
    e.persist();
    setTimings((prevState) => ({
      ...prevState,
      [key]: e.target.value,
    }));
  };

  const imagesArrayGenerator = (file) => {
    setError("");
    setmutableArray((prevImages) => {
      prevImages.splice(imageId, 1, file);
      return [...prevImages];
    });
  };

  function uploadImagesTos3AndAppendToPayload(previousImages) {
    let ImagePromiseArray = [];
    setLoading(true);
    //Uploading To S3
    newAddedImages.forEach((image) => {
      ImagePromiseArray.push(
        uploadToS3(image)
          .then((imageUrl) => {
            return { image_file: imageUrl };
          })
          .catch((err) => {
            setNewAddedImages(null);
            setLoading(false);
            return err;
          })
      );
    });

    Promise.all(ImagePromiseArray)
      .then((ImagePayloadArray) => {
        setImageFile(
          previousImages
            ? previousImages.concat(ImagePayloadArray)
            : ImagePayloadArray //Conditional Appending to Payload
        );
      })
      .catch((err) => {
        setLoading(false);

        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleEventCreation = async (payload) => {
    console.log("payload", payload);
    if (isConnection()) {
      setLoading(true);
      setError("");
      try {
        const res = await fetch(API_BASE_URL + apiRoutes.EXHIBITION, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${API_BASE_TOKEN}`,
          },
          body: JSON.stringify(payload),
        });
        // const result = await res.json();
        if (res.status >= 400) {
          // setImageFile(null)
          setLoading(false);
          if (payload.address === undefined) {
            setNoValidAddress("Please provide a valid address");
          } else {
            setError(RESPONSE_ERROR);
          }
          // console.log("res",res.json())
          setimagePreview(null);
        } else {
          setLoading(false);
          // props.history.push('/organizationProfile');
          setPopUp(true);
        }
      } catch (e) {
        setLoading(false);
        setError(RESPONSE_ERROR);
      }
    } else {
      setError(CONNECTION_ERROR);
    }
  };

  const splitMutableArray = () => {
    let newImages = [],
      previousImages = [];

    mutableArray?.map((img) => {
      if (isNotEmpty(img)) {
        if (img instanceof File) newImages.push(img);
        else previousImages.push(img);
      }
    });
    setLoading(false);
    setPreviousImages(previousImages);
    setNewAddedImages(newImages);
  };

  useEffect(() => {
    newAddedImages && handlePayloadCreation();
  }, [newAddedImages]);

  useEffect(() => {
    if (imageFile.length > 0) {
      const payload = {
        address: location.formatted_address,
        description: inputValue.description,
        name: inputValue.name,
        host_name: inputValue.host_name,
        host_email: inputValue.host_email,
        start_date: timings.startDate,
        end_date: timings.endDate,
        website: inputValue.website,
        collective: localStorage.getItem("CollectiveId"),
        images: imageFile,
        // business_hours: handleSelectedDays()
      };
      handleEventCreation(payload);
    }
  }, [imageFile]);

  const handleSubmit = (event) => {
    setContinueBtn(true);
    event.preventDefault();
    if (
      dateError !== "" ||
      verified === false ||
      !isValidWebsite ||
      !isValidEmail ||
      inputValue?.host_name.length === 0 ||
      inputValue?.name.length === 0 ||
      inputValue?.description.length === 0
    ) {
      setChecked(true);
      setValidated(true);
      event.stopPropagation();
    } else {
      setValidated(false);
      setError("");
      const imgArrayToBeUploaded = mutableArray.filter(Boolean);
      if (imgArrayToBeUploaded.length > 0) {
        splitMutableArray();
      } else {
        //When there is no image
        setValidated(true);
        event.stopPropagation();
        setError("Please Upload Atleast One Image");
        window.scrollTo(70, 0);
      }
    }
  };

  const handlePayloadCreation = () => {
    if (newAddedImages?.length > 0) {
      uploadImagesTos3AndAppendToPayload(
        previousImages.length ? previousImages : null
      );
    }
  };

  // const handleSelectedDays = () => {
  //   (selectedDays.length ? selectedDays : DaysOfWeek).forEach(
  //     (selectedDay, index) => {
  //       businessHours.push({
  //         weekday: (selectedDays.length ? selectedDay : index) + 1,
  //         from_hour: "23:59",
  //         to_hour: "23:59",
  //       });
  //     }
  //   );

  //   return businessHours;
  // };

  useEffect(() => {
    if (timings?.startDate > timings.endDate && timings?.endDate !== "") {
      setValidated(true);
      setDateError("Start Date Cannot be after End Date");
    } else {
      setValidated(false);
      setDateError("");
    }
  }, [timings.startDate, timings.endDate]);

  useEffect(() => {
    props.setIsCreateEventbtnVisible(false);
    props.setIsSearchbarVisible(false);
    // }
  }, [props]);
  return (
    <>
      <Helmet>
        <title>Submit New Event</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="w-100">
            <Sheet
              id="sheet"
              className="d-md-none "
              isOpen={isOpen}
              onClose={() => setOpen(false)}
              springConfig={Styled_Spring}
              initialSnap={0}
              onCloseStart={handleDoneClick}
            >
              <Sheet.Container>
                <Sheet.Header></Sheet.Header>
                <Sheet.Content>
                  <EventDays
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                  />
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
          </div>
          <div className="d-none d-lg-block">
            <FormModal
              isWeekDayModal
              show={isOpen}
              title={"Repeat Events"}
              handleClose={() => setOpen(false)}
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
            />
          </div>
          <div>
            {popUp ? (
              <div className="pl-0">
                <FormModal
                  show={popUp}
                  title="Event Submitted Successfully"
                  popUpInfo="Thank you for submitting your art event with Pollinate Art. Our team will review it for publishing."
                  ButtonsInPopUp
                  handleClose={() => props.history.push("/")}
                />
              </div>
            ) : (
              <div className="container create-event-container main">
                <div className="row">
                  <div className="col-12 col-lg-10 offset-lg-1 event">
                    <Link to="/" className="pointer d-inline">
                      <span className="d-flex">
                        <img src={BackBtn} alt="Back Button" />
                        <p className="lime mb-0 ml-2 ">{"Back"}</p>
                      </span>
                    </Link>
                    <h2 className="white mt-2">
                      {isEditEvent ? "Edit Event" : "Submit New Event"}
                    </h2>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-md-5 col-lg-4 offset-lg-1">
                    <label className="lime mb-0">Upload Images</label>
                    <span>
                      <p className="text-secondary text_small">
                        {" "}
                        ( Please Upload .png,.jpg or .jpeg files only)
                      </p>
                    </span>
                    <ImagePreviewer
                      imagePreview={imagePreview ? imagePreview : uploadImage}
                    />
                    <div className="mt-3 d-flex flex-wrap">
                      {" "}
                      {[...Array(numberOfImageUploaders)].map(
                        (elementInArray, index) => (
                          <div key={index}>
                            {" "}
                            <ImageUploader
                              id={index}
                              isRemoveImage
                              mutableArray={mutableArray}
                              setImgId={setimageId}
                              setmutableArray={setmutableArray}
                              imageFile={mutableArray[index]}
                              isEditEvent={isEditEvent}
                              setimagePreview={setimagePreview}
                              imageFileSetter={(file) =>
                                imagesArrayGenerator(file)
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                    <div className="mt-3">
                      <TextError children={error} />
                    </div>
                  </div>

                  <div className="col-12 col-md-7 col-lg-7 pr-0">
                    <Row className="container">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={(e) => handleSubmit(e)}
                        className="width-md"
                      >
                        <Row>
                          <Form.Group
                            as={Col}
                            xs="12"
                            md="6"
                            className="pr-0 mb-3"
                          >
                            <Form.Label className="lime">Name</Form.Label>
                            <input
                              type="text"
                              className="form"
                              id="host_name"
                              required
                              placeholder="Enter your name"
                              onChange={(e) => handleStateChange(e)}
                              value={inputValue?.host_name}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a name.
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            xs="12"
                            md="6"
                            className="pr-0 mb-3"
                          >
                            <Form.Label className="lime">Email</Form.Label>
                            <input
                              type="email"
                              className="form"
                              id="host_email"
                              required
                              placeholder="Enter your email "
                              onChange={(e) => handleStateChange(e)}
                              value={inputValue?.host_email}
                            />
                            {!isValidEmail && continueBtn && (
                              <TextError children="Please provide a valid Email." />
                            )}
                            {/* <Form.Control.Feedback type="invalid">
                              Please provide a email.
                            </Form.Control.Feedback> */}
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group
                            as={Col}
                            sm="12"
                            md="12"
                            className="pr-0 mb-3"
                          >
                            <Form.Label className="lime">
                              Title of Event
                            </Form.Label>
                            <input
                              type="text"
                              className="form"
                              required
                              placeholder="Enter your event name"
                              id="name"
                              value={inputValue.name}
                              onChange={(e) => handleStateChange(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a event name.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col} xs="6" className="pr-0 mb-3">
                            <Form.Label className="lime">Start Date</Form.Label>
                            <input
                              type="date"
                              className="form"
                              required
                              value={timings.startDate}
                              onChange={(e) => handleTimings(e, "startDate")}
                            />

                            {continueBtn && timings.startDate === "" && (
                              <TextError children="Please Enter a Start Date. " />
                            )}

                            <TextError children={dateError} />
                          </Form.Group>

                          <Form.Group as={Col} xs="6" className="pr-0 mb-3">
                            <Form.Label className="lime">End Date</Form.Label>
                            <input
                              type="date"
                              className="form"
                              required
                              value={timings.endDate}
                              onChange={(e) => handleTimings(e, "endDate")}
                            />

                            {continueBtn && timings.endDate === "" && (
                              <TextError children="Please Enter an End Date." />
                            )}
                          </Form.Group>
                        </Row>

                        {/* <Row>
                          <Form.Group
                            className="mb-3 pr-0"
                            as={Col}
                            xs="12"
                            controlId="repeat"
                          >
                            <Form.Label className="lime">Repeat</Form.Label>
                            <input
                              type="text"
                              className="form form-repeat"
                              value={
                                selectedDays.length === 0
                                  ? "Everyday"
                                  : repeatDays
                              }
                              readOnly
                              onClick={() => setOpen(true)}
                            />
                          </Form.Group>
                        </Row> */}
                        <Row>
                          <Form.Group as={Col} xs="12" className="pr-0 mb-3">
                            <Form.Label className="lime">Location</Form.Label>
                            <Autocomplete
                              apiKey={process.env.REACT_APP_GOOGLE_KEY}
                              onPlaceSelected={(place) => {
                                setLocation(place);
                              }}
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              placeholder="Address of Location"
                              required
                              options={{
                                types: ["geocode", "establishment"],
                                componentRestrictions: { country: "us" },
                              }}
                              defaultValue={location?.formatted_address}
                              className="form"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a Location.
                            </Form.Control.Feedback>
                            {location?.formatted_address === undefined && (
                              <TextError children={noValidAddress} />
                            )}
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group className="pr-0 mb-3" as={Col} xs="12">
                            <Form.Label className="lime">
                              Event Website
                            </Form.Label>
                            <input
                              className="form pr-0"
                              type="url"
                              id="website"
                              required
                              placeholder="www.pollinate.co"
                              value={inputValue.website}
                              onChange={(e) => handleStateChange(e)}
                            />
                            {!isValidWebsite && continueBtn && (
                              <TextError children="Please provide a valid URL." />
                            )}
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col} xs="12" className="pr-0 mb-4">
                            <Form.Label className="lime">
                              Description
                            </Form.Label>
                            <textarea
                              as="textarea"
                              row={6}
                              className="h-75 form"
                              type="text"
                              id="description"
                              required
                              placeholder="Enter your event's description "
                              onChange={(e) => handleStateChange(e)}
                              value={inputValue.description}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="mb-5 mt-0"
                            >
                              Please provide a description.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <div className="mt-2">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                            onChange={() => setVerified(true)}
                            className="recaptcha"
                          />
                          {checked && !verified && (
                            <div className="invalid-text">
                              Please check the box.
                            </div>
                          )}
                        </div>
                        <div className="row pl-3 mt-30">
                          <button className="lime-btn-with-blue-text border-0 sm_btn">
                            Submit New Event
                          </button>
                        </div>
                      </Form>
                    </Row>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(CreateEventScreen);
