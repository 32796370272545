import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import EventCardDetails from "./EventCardDetails";
import Map from "../components/MapForEvent";
import SliderComponent from "../components/SliderComponent";
import { mobileCheck } from "../helpers/util";

import "./EventPage.css";
import "../App.css";
import "../components/DiscoverLocation.css";

import { apiRoutes, baseURL } from "../api/apiRoutes";
import { LATITUDE, LONGITUDE } from "../Constants/Constant";

import BackBtn from "../assets/icons/BackBtn.svg";

function EventDetailsPage() {
  // var locationId;
  const history = useHistory();

  const apiBaseURL = baseURL + apiRoutes.EVENTS;
  let { id, filterType, cityId, cityName, Page_no } = useParams();

  let todayDate = new Date();

  const [cid, setCid] = useState(id ? id : 198);
  const [isOpen, setOpen] = useState(false);

  // const [cname, setCname] = useState("");
  const [eid, setEid] = useState(0);
  const [isLearnMore, setLearnMore] = useState(false);
  const [fullScreenStatus, setFullScreenStatus] = useState(false);
  const [fullScreenPic, setFullScreenPic] = useState("");
  const [isDiscoverLocation, setDiscoverLocation] = useState(false);
  const [mapZoomLevel, setMapZoomLevel] = useState(10);
  const [currentlyViewingLocationID, setCurrentlyViewingLocationID] =
    useState(null);
  const [selectedTreeId, setSelectedTreeId] = useState(0);
  const [state, setState] = useState({
    isCollapsible: false,
    ArtworkImage: "",
    ArtworkTitle: "",
    ArtworkDesc: "",
    ArtworkAudio: "",
    currentLocation: "",
    latitude: LATITUDE,
    longitude: LONGITUDE,
    data: [
      {
        type: "exhibition",
        lat: undefined, //When Not Allowed for location , no Marker is visible
        lon: undefined,
      },
    ],
    collective_slug_url: `${apiBaseURL + cityId}`,
  });

  const handleIsCollapsible = () => {
    setState((prevState) => ({
      ...prevState,
      isCollapsible: !state.isCollapsible,
    }));
  };

  const handleFullScreenImage = (pic) => {
    setFullScreenStatus(!fullScreenStatus);
    setFullScreenPic(pic);
  };
  const isCurrentDeviceMobile = mobileCheck();

  const handleCurrentLocation = (lat, long) => {
    setState((prevState) => ({
      ...prevState,
      latitude: lat,
      longitude: long,
      collective_slug_url:
        process.env.REACT_APP_API_URL +
        "experiencemap/?latmin=" +
        (lat - 0.05) +
        "&latmax=" +
        (lat + 0.05) +
        "&lonmin=" +
        (long - 0.05) +
        "&lonmax=" +
        (long + 0.05) +
        "&studio=false&exhibition=true&is_collective=false",
    }));
  };

  const handleArtworkImage = (image, title, desc, audio) => {
    setState((prevState) => ({
      ...prevState,
      ArtworkImage: image,
      ArtworkTitle: title,
      ArtworkDesc: desc,
      ArtworkAudio: audio,
    }));
  };

  const handleDiscoverLocation = (type) => {
    setDiscoverLocation(!type);
  };

  let url = state.collective_slug_url;

  const [allEventsListData, setAllEventsListData] = useState([]);

  async function getData(url) {
    const res = await fetch(url);
    const json = await res.json();
    setAllEventsListData([json]);
  }
  useEffect(() => {
    getData(url);
    if (isCurrentDeviceMobile) {
      setOpen(true);
    }
  }, []);

  const [apiData, setApiData] = useState("");

  let cityUrl = apiBaseURL + cityId;

  useEffect(() => {
    async function getData() {
      const res = await fetch(cityUrl);
      const json = await res.json();
      setApiData(json);
    }
    getData();
    // history.push({ pathname: data.id })
  }, []);

  return (
    <div>
      <Helmet>
        <title>{apiData.name ?? "Art Event - " + "Pollinate Event"}</title>
      </Helmet>
      <div
        onClick={() =>
          history.push({
            pathname: `/${
              filterType +
              "/" +
              cityName +
              "/" +
              id +
              "/" +
              cityId +
              "/" +
              Page_no +
              "/"
            }`,
          })
        }
        className="text-white  p-2 lg-hidden pointer sidebar-fixed-top "
      >
        <img
          src={BackBtn}
          className="mobile-back-btn-for-art-event"
          alt="Back Button"
        />
      </div>
      <div className="row no-gutters bg-black  ">
        <div className="w-100 sm-hidden display-direction-row_main bg-black col-md-4 h-90vh overflow-y-scroll ">
          <div id="list-container" className="overflow-auto">
            <div
              onClick={() =>
                history.push({
                  pathname: `/${
                    filterType +
                    "/" +
                    cityName +
                    "/" +
                    id +
                    "/" +
                    cityId +
                    "/" +
                    Page_no +
                    "/"
                  }`,
                })
              }
              className="px-4 d-lg-flex pointer align-items-center d-none pb-3 w-100 sidebar-fixed-top"
            >
              <img src={BackBtn} alt="Back Button" />
              <p className="event-series-text back-btn-chevron mb-1 ml-3 lg-show ">
                Back
              </p>
            </div>
            <div className="px-5 display-rows">
              <EventCardDetails apiData={apiData} />
            </div>
          </div>
        </div>

        <div className="col-md-8  overflow-y-scroll z-3">
          <Map
            cid={cid}
            eventsData={allEventsListData}
            eventsDataloading={false}
            eid={eid}
            todayDate={todayDate}
            latitude={state.latitude}
            isLearnMore={isLearnMore}
            setLearnMore={setLearnMore}
            setCurrentlyViewingLocationID={(id) =>
              setCurrentlyViewingLocationID(id)
            }
            zoomIn={(_) => setMapZoomLevel(18)}
            zoomOut={(_) => setMapZoomLevel(12)}
            longitude={state.longitude}
            isCollapsible={state.isCollapsible}
            handleCurrentLocation={handleCurrentLocation}
            collective_slug_url={state.collective_slug_url}
            ArtworkAudio={state.ArtworkAudio}
            ArtworkTitle={state.ArtworkTitle}
            ArtworkDesc={state.ArtworkDesc}
            ArtworkImage={state.ArtworkImage}
            handleArtworkImage={handleArtworkImage}
            isDiscoverLocation={isDiscoverLocation}
            handleDiscoverLocation={handleDiscoverLocation}
            onCustomClick={(id) => setEid(id)}
            handleFullScreenImage={handleFullScreenImage}
            setEid={setEid}
            zoomLevel={mapZoomLevel}
            setSelectedTreeId={setSelectedTreeId}
            currentlyViewingLocationID={currentlyViewingLocationID}
            location_data={state.data}
          />
        </div>
        {/* <div className="fixed-bottom bg-black h-20 lg-hidden">
        <div
          className="text-center p-2 text-white"
          onClick={() => {
            setOpen(true);
          }}
        >
         <h3 className="text-parrot">{apiData.name}</h3>
         <h6 className="text-white">More Details &hellip;</h6>
         
        </div>
       
      </div> */}
        <SliderComponent
          eventDetail={apiData}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </div>
    </div>
  );
}

export default withRouter(EventDetailsPage);
