// import { isAuthenticated } from '../helpers/helpers';
import { API_BASE_URL, API_BASE_TOKEN } from "./apiConstants";
import { apiRoutes } from "./apiRoutes";

export async function uploadToS3(file) {
  const generateRandomString = (length = 12) =>
    Math.random().toString(20).substr(2, length);
  let data = new FormData();
  data.append("file", file);
  
  try {
    const res = await fetch(
      API_BASE_URL + apiRoutes.UPLOAD + generateRandomString(),
      {
        method: "PUT",
        body: data,
        headers: {
          Authorization: `Token ${API_BASE_TOKEN}`,
        },
      }
    );
    const json = await res.json();
    return Promise.resolve(json.file_url);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getObjectDetails(endpoint, id) {
  let headers = {};
  // if (isAuthenticated()) {
  headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
  };

  const res = await fetch(API_BASE_URL + endpoint + (id ? id + "/" : ""), {
    method: "GET",
    headers,
  });
  const json = await res.json();
  if (res.status === 404) {
    return Promise.reject("Not Found");
  } else {
    return Promise.resolve(json);
  }
  // } else {
  //   return Promise.reject('Not Authenticated');
  // }
}
