import React from "react";
import BackBtn from "../../assets/icons/BackBtn.svg";
import ForwardBtn from "../../assets/icons/ForwardBtn.svg";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

function ImageCarousel(props) {
  return (
    <div >
      <CarouselProvider
        naturalSlideWidth={125}
        naturalSlideHeight={250}
        isIntrinsicHeight
        totalSlides={props.imgArray.length}
        infinite={true}
        style={{overflow:"hidden"}}
      >
        <Slider style={{maxWidth:'75em',border:'3px solid black'}} >
          {props.imgArray.map((image, index) => (
            <Slide index={index} key={index}>
              <img
                className='artwork-image-container'
                src={image.image_file || image.primary_image}
                alt=''
              />
            </Slide>	
          ))}
        </Slider>
        {props.imgArray.length>1 && (
          <div >
			  <div className=''>
            <ButtonBack className="bg-black carousel-back-button">
              <img src={BackBtn} alt="Back Btn"/>
            </ButtonBack>
			  </div>
			  <div className=''>

            <ButtonNext className="bg-black carousel-forward-button">
              <img src={ForwardBtn} alt="Forward Btn"/>
            </ButtonNext>
			  </div>
          </div>
        )}
      </CarouselProvider>
    </div>
  );
}
export default ImageCarousel;
