import React, { useState } from "react";
import Geocode from "react-geocode";
import { MapMark } from "../assets/Icons";

const GeocodeMap = ({ eventAddress, lat, lon }) => {
  const [address, setAddress] = useState("");
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY_GEOCODE);
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  Geocode.fromLatLng(lat, lon).then(
    (response) => {
      const adress = response.results[0].formatted_address;
      setAddress(adress);
    },
    (error) => {
      console.error(error);
    }
  );
  return (
    <div className="mt-3 mb-3">
      <div className="display-flex ">
        <MapMark />
        <a
          href={`https://www.google.com/maps?q=${
            eventAddress.includes(",") ? "" : eventAddress
          }${" "}${address}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="location ta-start" style={{ marginLeft: "5px" }}>
            {eventAddress}
          </div>
        </a>
      </div>
    </div>
  );
};

export default GeocodeMap;
