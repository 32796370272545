import React, { useEffect, useState } from "react";
import "./EventHomepage.css";
import { useLocation, withRouter } from "react-router-dom";
import Tabs from "../components/common/Tabs";

// import LazyLoad from "react-lazyload";

import { useGetFetch } from "../hooks/useGetFetch";
import { Loader, numberOfEvents } from "../helpers/helpers";
import { isNotEmpty } from "../helpers/util";
import { HomePageTabs, screenWidth } from "../Constants/Constant";
import Helmet from "react-helmet";

function Cities(props) {
  const location = useLocation();

  let data, url, emptyMessage;
  // TODO: Fix the name
  const [state, setState] = useState({
    filter: location?.state?.filterType
      ? location?.state?.filterType
      : "featured",
  });

  let [cities, isCitiesLoading] = useGetFetch(
    process.env.REACT_APP_API_URL + "city/?event=true",
    (arg) => arg.sort((a, b) => b.number_of_events - a.number_of_events) //sorting Cities by Callback fn
  );

  let [collective, isCollectiveLoading] = useGetFetch(
    process.env.REACT_APP_API_URL + "collective/",
    (arg) => arg.sort((a, b) => b.number_of_events - a.number_of_events)
  );
  let [pastEvents, isPastEventsLoading] = useGetFetch(
    process.env.REACT_APP_API_URL + "collective/?past_events=true"
  );

  switch (state.filter) {
    case "featured":
      data = collective;
      url = "/exhibition";
      emptyMessage = "Currently, There are no collectives";
      break;
    case "past-events":
      data = pastEvents;
      url = "/past-events";
      emptyMessage = "Past events are not available right now";
      break;
    default:
      data = null;
  }

  const handleBtnClick = (id, name, isCity, art_event_url) => {
    props.history.push({
      state: { cityName: name },
      pathname: `${
        isNotEmpty(art_event_url)
          ? art_event_url
          : `${isCity ?(state.filter === "past-events"? `city-past-events`:`city`) : url}/${name}/${id}`
      }`,
    });
  };

  useEffect(() => {
    // props.setIsNav(screenWidth >= 768 ? true : false);
    props.setIsNav(true);
    props.setCityInLocalStorage(false);
    props.setIsCreateEventbtnVisible(true);
    props.setIsSearchbarVisible(true);
    localStorage.clear();
  }, []);
  return (
    <div className="container  min-vh-100">
      <Helmet>
        <title>Events - Pollinate Art</title>
      </Helmet>
      {/* cities-fixed-top  */}
      <div className="cities-fixed-top"> 
      <p className="homepage-heading lime">Events</p>
      <Tabs TabValues={HomePageTabs} state={state} setState={setState} />
      </div>

      <div className="container-fluid set-margin-top-for-cities-page pl-0">
        {/* {state.filter === "cities" && (isCitiesLoading ? <Loader /> : null)} */}
        {state.filter === "featured" &&
          (isCollectiveLoading ? <Loader /> : null)}
        {state.filter === "past-events" &&
          (isPastEventsLoading ? <Loader /> : null)}
        {data?.length ? (
          <div className="city-list-container">
            {/* {state.filter === "featured" && ( */}
              <>
            
                <p className="text-left no-of-cities">Cities</p>
                <div className="row">
                  {cities.map((element, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        handleBtnClick(element.id, element.name, true)
                      }
                      className="mb-4 w-100 col-sm-12 col-md-4 city-names"
                    >
                    
                      <img
                        loading="lazy"
                        src={element.thumbnail}
                        alt="New York"
                        height="170px"
                        className="w-100 event-image"
                      />
                

                      <div className="bg-white px-3 py-2 br--9">
                        <p className="mb-1 city-names">{element.name}</p>
                        {state.filter === "featured" ? (
                          <p className="info-window-date mb-0">
                            {numberOfEvents(element?.number_of_events)}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  ))}{" "}
                </div>
                <p className="text  -left no-of-cities">Partners</p>
               
              </>
      
            <div className="row">
              {data.map((element, index) => (
                <div
                  key={index}
                  onClick={(e) =>
                    handleBtnClick(
                      element.id,
                      element.name,
                      false,
                      element.art_event_url
                    )
                  }
                  className="mb-4 w-100 col-sm-12 col-md-4 city-names"
                >
                  <img
                    loading="lazy"
                    src={element.primary_image}
                    alt="New York"
                    height="170px"
                    className="w-100 event-image"
                  />

                  {/* </LazyLoad> */}
                  <div className="bg-white px-3 py-2 br--9">
                    <p className="mb-1 city-names">{element.name}</p>

                    {state.filter === "featured" ? (
                      <p className="info-window-date mb-0">
                        {numberOfEvents(element?.number_of_events)}
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className="text-left no-of-cities mt-3">{emptyMessage}</p>
        )}
      </div>
    </div>
  );
}

export default withRouter(Cities);
