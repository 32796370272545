import React from "react";
import { useHistory } from "react-router-dom";

import BackBtn from "../assets/icons/BackBtn.svg";

const ErrorPage = () => {
  const history = useHistory();
  return (
    <div className="mt-8 p-4 mt-9rem">
      <div className="h1 text-center text-muted ">
        Error! Something went wrong.
      </div>
      <div className="d-flex justify-content-center">
        {" "}
        <div
          onClick={() =>
            history.push({
              pathname: `/`,
            })
          }
          className="px-4 d-flex pointer align-items-center my-3 "
        >
          <img src={BackBtn} alt="Back Button" />
          <p className="event-series-text  mb-1 ml-3">Go To Home</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
