import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";

import EventsCardSidebar from "./EventsCardSidebar";
import { Loader, SmallLoader } from "../helpers/helpers";
import { mobileCheck } from "../helpers/util";

import "../components/DiscoverLocation.css";

import BackBtn from "../assets/icons/BackBtn.svg";
import { EVENT_DETAIL_END_DATE, TODAY_DATE } from "../Constants/Constant";

const EventsListSidebar = ({
  eventsListDataResult,
  allEventsListData,
  getData,
  history,
  currentUrl,
  setFirstElementRef,
  firstElementRef,
}) => {
  const [nextUrl, setNextUrl] = useState("");
  const [lastItemApiUrl, setLastItemApiUrl] = useState("");
  const [firstItemApiUrl, setFirstItemApiUrl] = useState("");
  let { cityName, filterType } = useParams();

  const pastevent =
    filterType === "past-events" || filterType === "city-past-events";

  const isCurrentDeviceMobile = mobileCheck();
  useEffect(() => {
    getData(nextUrl);
  }, [nextUrl]);

  useEffect(() => {
    // eventsListDataResult?.map((eventDetail, index) => {
    //   if (
    //     pastevent
    //       ? EVENT_DETAIL_END_DATE(eventDetail) < TODAY_DATE
    //       : EVENT_DETAIL_END_DATE(eventDetail) >= TODAY_DATE
    //   )
    //     return eventDetail;
    // });

    let firstItemUrl = eventsListDataResult[0]?.previous;
    setFirstItemApiUrl(firstItemUrl);
    let lastItemUrl =
      eventsListDataResult[eventsListDataResult.length - 1]?.next;
    setLastItemApiUrl(lastItemUrl);
  }, [eventsListDataResult, pastevent]);

  const observer = useRef();
  const observerNew = useRef();

  const firstCardElementRef = useCallback(
    (node) => {
      if (observerNew.current) observerNew.current.disconnect();
      observerNew.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // setTimeout(()=>{
          setFirstElementRef(true);
          setNextUrl(firstItemApiUrl);
          // },3000)
        }
      });
      if (node) observerNew.current.observe(node);
    },
    [firstItemApiUrl, setFirstElementRef]
  );

  const lastCardElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setFirstElementRef(false);
          setNextUrl(lastItemApiUrl);
        }
      });
      if (node) observer.current.observe(node);
    },
    [lastItemApiUrl, setFirstElementRef]
  );

  if (eventsListDataResult.length === 0)
    return (
      <div className="pb-10">
        <Loader />
      </div>
    );

  return (
    <>
      {window === window.parent && (
        <div
          onClick={() => history.push({ pathname: "/" })}
          className="px-4 d-md-flex pointer align-items-center d-none pb-3 w-37 sidebar-fixed-top"
        >
          <img src={BackBtn} alt="Back Button" />
          <p className="event-series-text back-btn-chevron mb-1 ml-3 lg-show ">
            {cityName}
          </p>
        </div>
      )}

      <div className="display-rows">
        {firstItemApiUrl && (
          <div ref={firstCardElementRef} className="loader-mobile">
            <SmallLoader />
          </div>
        )}
        {eventsListDataResult?.map((eventDetail, index) => {
          // if (
          //   pastevent
          //     ? EVENT_DETAIL_END_DATE(eventDetail) < TODAY_DATE
          //     : EVENT_DETAIL_END_DATE(eventDetail) >= TODAY_DATE
          // )
          return (
            <div className="mb-0 mb-md-4  ml-4 mr-4 card-sm" key={index}>
              <EventsCardSidebar
                eventDetail={eventDetail}
                history={history}
                getData={getData}
                firstElementRef={firstElementRef}
                eventsListDataResult={eventsListDataResult}
              />
            </div>
          );
        })}
        {/* Showing Loader While fetching next page */}
        <div
          ref={lastCardElementRef}
          className="text-center text-white loader-mobile mb-3"
        >
          {lastItemApiUrl !== null ? (
            <SmallLoader />
          ) : (
            !isCurrentDeviceMobile && "No More Events"
          )}
        </div>
      </div>
    </>
  );
};

export default EventsListSidebar;
