export const MapMark = () => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.29688 17.8984C6.4375 18.1445 6.68359 18.25 7 18.25C7.28125 18.25 7.52734 18.1445 7.70312 17.8984L10.0586 14.5234C11.2188 12.8359 11.9922 11.7109 12.3789 11.1133C12.9062 10.2695 13.2578 9.56641 13.4688 9.00391C13.6445 8.44141 13.75 7.77344 13.75 7C13.75 5.80469 13.4336 4.67969 12.8359 3.625C12.2031 2.60547 11.3945 1.79688 10.375 1.16406C9.32031 0.566406 8.19531 0.25 7 0.25C5.76953 0.25 4.64453 0.566406 3.625 1.16406C2.57031 1.79688 1.76172 2.60547 1.16406 3.625C0.53125 4.67969 0.25 5.80469 0.25 7C0.25 7.77344 0.320312 8.44141 0.53125 9.00391C0.707031 9.56641 1.05859 10.2695 1.62109 11.1133C1.97266 11.7109 2.74609 12.8359 3.94141 14.5234C4.89062 15.8945 5.66406 17.0195 6.29688 17.8984ZM7 9.8125C6.22656 9.8125 5.55859 9.56641 4.99609 9.00391C4.43359 8.44141 4.1875 7.77344 4.1875 7C4.1875 6.22656 4.43359 5.59375 4.99609 5.03125C5.55859 4.46875 6.22656 4.1875 7 4.1875C7.77344 4.1875 8.40625 4.46875 8.96875 5.03125C9.53125 5.59375 9.8125 6.22656 9.8125 7C9.8125 7.77344 9.53125 8.44141 8.96875 9.00391C8.40625 9.56641 7.77344 9.8125 7 9.8125Z"
        fill="#FB5959"
      />
    </svg>
  );
};

export const VrIcon = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3727 26.5261L6.62273 21.9818C6.29394 21.7939 6.04148 21.5356 5.86534 21.2068C5.6892 20.878 5.60114 20.5258 5.60114 20.15V11.0966C5.60114 10.7208 5.6892 10.3686 5.86534 10.0398C6.04148 9.71098 6.29394 9.45265 6.62273 9.26477L14.408 4.65C14.7367 4.46212 15.1008 4.36818 15.5 4.36818C15.8992 4.36818 16.2633 4.46212 16.592 4.65L24.3773 9.26477C24.7061 9.45265 24.9585 9.71098 25.1347 10.0398C25.3108 10.3686 25.3989 10.7208 25.3989 11.0966V20.15C25.3989 20.5258 25.3049 20.878 25.117 21.2068C24.9292 21.5356 24.6708 21.7939 24.342 21.9818L16.4864 26.5261C16.1576 26.714 15.8053 26.808 15.4295 26.808C15.0538 26.808 14.7015 26.714 14.3727 26.5261ZM14.4432 24.0955V16.2045L7.75 12.3648V20.0795L14.4432 24.0955ZM16.5568 24.0955L23.2852 20.0795V12.3648L16.5568 16.2045V24.0955ZM0 6.65795V3.94545C0 2.84167 0.381629 1.90814 1.14489 1.14489C1.90814 0.381629 2.84167 0 3.94545 0H6.65795V2.11364H3.94545C3.42879 2.11364 2.99432 2.28977 2.64205 2.64205C2.28977 2.99432 2.11364 3.42879 2.11364 3.94545V6.65795H0ZM3.94545 31C2.84167 31 1.90814 30.6184 1.14489 29.8551C0.381629 29.0919 0 28.1583 0 27.0545V24.342H2.11364V27.0545C2.11364 27.5712 2.28977 28.0057 2.64205 28.358C2.99432 28.7102 3.42879 28.8864 3.94545 28.8864H6.65795V31H3.94545ZM24.342 30.8591V28.7455H27.0545C27.5712 28.7455 28.0057 28.5693 28.358 28.217C28.7102 27.8648 28.8864 27.4303 28.8864 26.9136V24.2011H31V26.9136C31 28.0174 30.6184 28.9509 29.8551 29.7142C29.0919 30.4775 28.1583 30.8591 27.0545 30.8591H24.342ZM28.8864 6.65795V3.94545C28.8864 3.42879 28.7102 2.99432 28.358 2.64205C28.0057 2.28977 27.5712 2.11364 27.0545 2.11364H24.342V0H27.0545C28.1583 0 29.0919 0.381629 29.8551 1.14489C30.6184 1.90814 31 2.84167 31 3.94545V6.65795H28.8864ZM15.5 14.3023L22.1932 10.4273L15.5 6.5875L8.80682 10.4273L15.5 14.3023Z"
      fill="white"
    />
  </svg>
);
