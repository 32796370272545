/**
 * Contains environment key constants.
 * API_BASE_URL points at the django api server.
 */
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const API_BASE_TOKEN = process.env.REACT_APP_API_TOKEN;
// DEV
// export const API_BASE_URL_DEV = process.env.REACT_APP_API_URL_DEV;
// export const API_BASE_DEV_TOKEN = process.env.REACT_APP_API_TOKEN_DEV;
//
export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_KEY;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const RESPONSE_ERROR = "Something went wrong";
export const COLLECTIVE_SLUG_QUERY_PARAMS =
  "?latmin=-90&latmax=90&lonmin=-180&lonmax=180&studio=false&exhibition=true";
