import { toast } from 'react-toastify';

export const successToast = (message) => {
    if (message) {
      toast.success(message, { theme: "dark", position: 'bottom-right', hideProgressBar: true })
    }
  }

  export const errorToast = (message) => {
    if (message) {
      toast.error(message, { theme: "dark", position: 'bottom-right', hideProgressBar: true })
    }
  }


  export const loadingToast = (message) => {
    if (message) {
      toast.info(message, { theme: "dark", position: 'bottom-right', hideProgressBar: false, closeOnClick:true })
    }
}