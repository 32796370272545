import { XIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import uploadImage from '../../assets/icons/upload_image.svg';
import UploadPhoto from '../../assets/icons/image-gallery-svgrepo-com.svg';
import HumanFace from '../../assets/icons/accountNew.svg'
import '../DiscoverLocation.css'
// import { isNotEmpty } from '../../helpers/util';
function ImageUploader({
  imageFileSetter,
  setimagePreview,
  id,
  isRemoveImage,
  // isEditEvent,
  setImgId,
  imageFile,
  mutableArray,
  setmutableArray,
  uploadImageBtnClass,
  isChangeImage,
  humanFace
}) {
  const [imageUpload, setimageUpload] = useState(null);

  const previewImage = () => {
    setimagePreview(imageUpload);
  };



  // useEffect(() => {
  //   if (isEditEvent && !(imageFile instanceof File)) {
  //     setimageUpload(imageFile?.image_file);
  //   }
  // }, [imageFile, isEditEvent]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      setimageUpload(reader.result);
      if (setimagePreview) {
        setimagePreview(reader.result);
      }
      imageFileSetter(file);
    };
    file && reader.readAsDataURL(file);
  };
  const handleRemoveImage = (event) => {
    setimageUpload(null);
    // const handleRemoveImage = () => {
    setimagePreview(uploadImage);

    setmutableArray((imgArray) => {
      imgArray.splice(id, 1, null);
      return [...imgArray];
    });
    //   imageFileSetter(null);
    //   handleRemoveImage();
    // };
  };

  return (
    <div>
      <input
        type='file'
        id={`upload-btn-input${id}`}
        accept='image/png, image/jpg, image/jpeg'
        hidden
        onChange={handleImageChange}
      />
      {isRemoveImage && imageUpload && (
        <XIcon
          onClick={(e) => handleRemoveImage(e)}
          className='delete-icon'
        />
      )}
      <div
        className='d-flex flex-column mr-2 '
        onClick={
          setimagePreview
            ? imageUpload
              ? previewImage
              : () => setImgId(id)
            : null
        }
      >
       {imageUpload ? <img 
            src= {imageUpload} 
          className={
            uploadImageBtnClass ? uploadImageBtnClass : 'upload-img-btn'
          }
          alt='avatar'
        /> : 
        <label
          style={{
            backgroundImage: `url(${humanFace ? HumanFace :UploadPhoto})`,
          }}
          htmlFor={!imageUpload ? `upload-btn-input${id}` : ''}
          className={
            uploadImageBtnClass ? uploadImageBtnClass : 'upload-img-btn'
          }
          alt='avatar image'
        ></label>}
        
        {isChangeImage && imageUpload && (
          <label
            htmlFor={`upload-btn-input${id}`}
            className='mb-1 pointer lime ta-center change-image'
          >
            Change Image
          </label>
        )}
      </div>  
    </div>
  );
}

export default ImageUploader;
