import React, { useCallback, useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import "../App.css";
import { LATITUDE, LONGITUDE } from "../Constants/Constant";
import ArtEventMap from '../components/ArtEventMap';
import "../components/DiscoverLocation.css";
import EventListComponent from "../components/EventListComponent";
import { getQueryParamAsBoolean } from '../helpers/helpers';
import useQuery from "../hooks/useQuery";

function AiopDress(props) {
  let location = useLocation();
  let query = useQuery();
  let is_nav = getQueryParamAsBoolean(query, 'is_nav')
  let is_back = getQueryParamAsBoolean(query, 'is_back')

  let todayDate = new Date();
  let id = '216', component, filter;
  const [eid, setEid] = useState(0);
  const [isLearnMore, setLearnMore] = useState(false);
  const [currentlyViewingLocationID, setCurrentlyViewingLocationID] =
    useState(null);

  const [cid, setCid] = useState(0);
  const [cname, setCname] = useState("");
  const [isDiscoverLocation, setDiscoverLocation] = useState(false);
  const [mapZoomLevel, setMapZoomLevel] = useState(11);

  const [selectedTreeId, setSelectedTreeId] = useState(0);
  const [state, setState] = useState({
    isCollapsible: false,
    ArtworkImage: "",
    ArtworkTitle: "",
    ArtworkDesc: "",
    ArtworkAudio: "",
    currentLocation: "",
    latitude: LATITUDE,
    longitude: LONGITUDE,
    data: [
      {
        type: "exhibition",
        lat: undefined, //When Not Allowed for location , no Marker is visible
        lon: undefined,
      },

    ],
    collective_slug_url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_COLLECTIVE_SLUG}&collective_id=${id}`,
  });

  const handleIsCollapsible = () => {
    setState((prevState) => ({
      ...prevState,
      isCollapsible: !state.isCollapsible,
    }));
  };

  const handleArtworkImage = (image, title, desc, audio) => {
    setState((prevState) => ({
      ...prevState,
      ArtworkImage: image,
      ArtworkTitle: title,
      ArtworkDesc: desc,
      ArtworkAudio: audio,
    }));
  };

  const handleDiscoverLocation = (type) => {
    setDiscoverLocation(!type);
  };

  const [loading, setLoading] = useState(true)
  const [eventsData, setEventsData] = useState()

  useEffect(() => {
    localStorage.setItem("EventName", "Artists");
    !is_nav ? props.setIsNav(true) : props.setIsNav(false);
  }, [is_nav, props]);
  // let [eventsData, eventsDataloading] = useGetFetch(state.collective_slug_url);

  const fetchGet = useCallback(async () => {
    try {
      const res = await fetch(state.collective_slug_url, {
        method: 'GET',
        headers: {
          Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (res.status >= 400) {
        console.log('Error:', res.status);
      } else {
        const json = await res.json();
        setEventsData(json);
        localStorage.setItem('aiop-dress', JSON.stringify(json));
        setLoading(false);

      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const storedData = JSON.parse(localStorage.getItem('aiop-dress'));
        if (storedData === null) {
          try {
            await fetchGet();
          } catch (error) {
            console.error('Error fetching data from API:', error);
          }
        } else {
          setTimeout(() => {
            setEventsData(storedData);
            setLoading(false);
          }, 100);
        }
      } catch (error) {
        console.error('Error retrieving data from local storage:', error);
      }

    };

    fetchData();
  }, [state.collective_slug_url]);


  return (
    <div className="art-event-map-and-list  display-direction-row_main ">
      <div
        id="list-container"
        className="xs-100 w-37 chashama-event card-list-color-and-height overflow-auto"
      >
        <div className="">
          {/* <EventsListSidebar
            eventsListDataResult={eventsData}
            allEventsListData={[]}
            getData={getData}
            history={props.history}
          /> */}

          <EventListComponent
            showBackButton={!is_back}
            filter={filter}
            eid={eid}
            eventsData={eventsData}
            eventsDataloading={loading}
            setIsNav={props.setIsNav}
            isLearnMore={isLearnMore}
            setLearnMore={setLearnMore}
            setSelectedTreeId={setSelectedTreeId}
            history={props.history}
            collective_slug_url={state.collective_slug_url}
            ArtworkImage={state.ArtworkImage}
            handleArtworkImage={handleArtworkImage}
            isDiscoverLocation={isDiscoverLocation}
            handleDiscoverLocation={handleDiscoverLocation}
            cid={cid}
            setCityInLocalStorage={props.setCityInLocalStorage}
            setEid={setEid}
            todayDate={todayDate}
            onClick={(id) => setEid(id)}
            resetCid={() => setCid(0)}
            name={cname}
            handleZoomIn={(_) => setMapZoomLevel(19)}
            handleZoomOut={(_) => setMapZoomLevel(12)}
            currentlyViewingLocationID={currentlyViewingLocationID}
            setCurrentlyViewingLocationID={(id) =>
              setCurrentlyViewingLocationID(id)
            }
            isCollapsible={state.isCollapsible}
            handleIsCollapsible={handleIsCollapsible}
            url={location.pathname}
          />
        </div>
      </div>
      <div
        style={
          // id === "195"
          //   ? { maxHeight: "100vh", marginTop: "-25px" }
          //   :
          { maxHeight: "100vh" }
        }
        className='xs-100 w-100 z-3'
      >
        {loading ? (
          <div
            style={{ position: "absolute", width: "100%", height: "100%" }}
          ></div>
        ) :
          <div className={`event-map-container ${!is_nav && "mt-5"}`}>
            <ArtEventMap
              showBackButton={!is_back}
              filter={filter}
              eid={eid}
              eventsData={eventsData}
              eventsDataloading={loading}
              setIsNav={props.setIsNav}
              isLearnMore={isLearnMore}
              setLearnMore={setLearnMore}
              setSelectedTreeId={setSelectedTreeId}
              history={props.history}
              collective_slug_url={state.collective_slug_url}
              ArtworkImage={state.ArtworkImage}
              handleArtworkImage={handleArtworkImage}
              isDiscoverLocation={isDiscoverLocation}
              handleDiscoverLocation={handleDiscoverLocation}
              cid={cid}
              setCityInLocalStorage={props.setCityInLocalStorage}
              setEid={setEid}
              todayDate={todayDate}
              onClick={(id) => setEid(id)}
              resetCid={() => setCid(0)}
              name={cname}
              handleZoomIn={(_) => setMapZoomLevel(19)}
              handleZoomOut={(_) => setMapZoomLevel(12)}
              currentlyViewingLocationID={currentlyViewingLocationID}
              setCurrentlyViewingLocationID={(id) =>
                setCurrentlyViewingLocationID(id)
              }
              isCollapsible={state.isCollapsible}
              handleIsCollapsible={handleIsCollapsible}
              url={location.pathname}
            />
          </div>
        }
      </div>
    </div>
  );
}
// ArtEvent.defaultProps = {
//   showBackButton: true,
//   filter: true,
// };

export default withRouter(AiopDress);
