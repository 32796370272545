// eslint-disable-next-line import/prefer-default-export
export const baseURL = process.env.REACT_APP_API_URL;
export const apiRoutes = {
  UPLOAD: "upload/",
  CITY_ID: "city_id",
  COLLECTIVE: "collective/",
  EXHIBITION: "exhibition/",
  EVENTS: "events/",
  LOGIN: "rest-auth/login/",
  EVENT_ARTIST: "eventartist/",
  COLLECTIVE_ID: "collective_id",
};
