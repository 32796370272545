import React from 'react';
import SelectedButton from '../assets/icons/Selected_button.svg';
import UnselectedButton from '../assets/icons/Unselected_button.svg';
import { DaysOfWeek } from '../Constant';
import '../pages/EventHomepage.css';

function EventDays(props) {
  const handleSelectedDay = (key) => {
    if (props?.selectedDays?.includes(key)) {
      props.setSelectedDays((selectedDays) => {
        selectedDays.splice(selectedDays.indexOf(key), 1);

        return [...selectedDays];
      });
    } else {
      props.setSelectedDays((selectedDays) => {
        return [...selectedDays, key];
      });
    }
  };

  return (
    <div className='bg-black container'>
      <p
        style={{ fontSize: '27px' }}
        className='text-center d-md-none event-title '
      >
        Repeat Events
      </p>
      <div className='week-days-modal-and-sheet'>
        {DaysOfWeek.map((day, key) => {
          return (
            <div
              key={key}
              style={{ height: '50px' }}
              onClick={(_) => handleSelectedDay(key)}
              className='d-flex align-items-center justify-content-between pointer  mb-4'
            >
              <p className='event-days text-light mb-0'>Every {day}</p>
              <img
                alt=''
                src={
                  props?.selectedDays?.includes(key)
                    ? SelectedButton
                    : UnselectedButton
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default EventDays;
