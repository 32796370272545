import React from 'react';
function ImagePreviewer({ height, width, imagePreview }) {
  return (
    <div className='image-previewer'>
      {imagePreview && <img height={height} width={width} src={imagePreview} alt="uploadimg" />}
    </div>
  );
}

export default ImagePreviewer;

ImagePreviewer.defaultProps = {
  height: '255px',
  width: '265px',
};
