import React, { useState } from "react";
import RedArrow from "../assets/icons/RedArrow.svg";
import "../components/DiscoverLocation.css";
import "../components/EventComponent.css";
import GeocodeMap from "../components/GeocodeMap";
import ArtworkPopUp from "../components/ArtworkPopUp";

function EventCardDetails({ apiData }) {
  //TODO : remove artist field from data

  const [state, setState] = useState({
    ArtworkImage: "",
    ArtworkTitle: "",
    ArtworkDesc: "",
    ArtworkAudio: "",
  });
  const [isArtworkPresent, setisArtworkPresent] = useState(false);

  const handleArtworkImage = (image, title, desc, audio, isArtworkPresent) => {
    setisArtworkPresent(isArtworkPresent);

    setState((prevState) => ({
      ...prevState,
      ArtworkImage: image,
      ArtworkTitle: title,
      ArtworkDesc: desc,
      ArtworkAudio: audio,
    }));
  };

  return (
    <div className="bg-black sm-hidden overflow-y-scroll">
      {state.ArtworkImage && (
        <div className="w-50">
          <ArtworkPopUp
            imgArray={isArtworkPresent ? apiData.art_work : apiData.images}
            ArtworkAudio={state.ArtworkAudio}
            ArtworkTitle={state.ArtworkTitle}
            ArtworkDesc={state.ArtworkDesc}
            ArtworkImage={state.ArtworkImage}
            handleArtworkImage={handleArtworkImage}
          />
        </div>
      )}
      <div className="hide-scroll w-100 display-direction-row overflow-x-scroll  ta-start">
        {apiData.images &&
          apiData.images.map((item, index) => (
            <div key={index} className="mt-20 mr-4 flex-direction-row">
              <ImageComponent
                key={item.id}
                url={item.image_file}
                handleArtworkImage={() => handleArtworkImage(item.image_file)}
              />
            </div>
          ))}
      </div>
      <div className="w-100 display-direction-row mt-4 ">
        <div className="w-100 d-flex justify-content-between">
          <div className="w-60 d-flex">
            <p className="event-title ta-start mb-2">{apiData.name}</p>
          </div>
        </div>
      </div>

      {apiData.address && (
        <GeocodeMap
          eventAddress={apiData.address}
          lat={apiData.lat}
          lon={apiData.lon}
        />
      )}
      <p className="event-date ta-start mt-1 ">{apiData.timings}</p>

      {apiData.description && (
        <>
          <p className="event-desc-heading mb-1 mt-4 ta-start">Description</p>
          <p
            className="event-desc ta-start mb-4"
            dangerouslySetInnerHTML={{ __html: apiData.description }}
          />
        </>
      )}
      {apiData?.website && (
        <a href={apiData.website} target="_blank" rel="noreferrer">
          <div className="d-flex">
            <p className="view-website mb-1 mr-1 mt-1">Learn more</p>
            <img src={RedArrow} width="20px" alt="data" />
          </div>
        </a>
      )}
      {apiData?.ar_link && (
        <a href={apiData.ar_link} target="_blank" rel="noreferrer">
          <div className="d-flex mt-3 mb-3">
            <p className="view-website mb-1 mr-1 mt-1">View In AR</p>
            <img src={RedArrow} width="20px" alt="link" />
          </div>
        </a>
      )}
      {apiData?.marketplace_url && (
        <a href={apiData.marketplace_url} target="_blank" rel="noreferrer">
          <div className="d-flex mt-3 mb-3">
            <p className="view-website mb-1 mr-1 mt-1">View On Marketplace</p>
            <img src={RedArrow} width="20px" alt="marketplace" />
          </div>
        </a>
      )}
      {apiData?.audiovideo && apiData?.audiovideo?.length > 0 && (
        <div className="mt-30">
          <p className="artwork-title ta-start mb-2">Hear More</p>
          <div className="w-100 display-direction-row ta-start">
            {apiData?.audiovideo &&
              apiData?.audiovideo.map((item) => (
                <ArtworkComponent
                  handleArtworkImage={() =>
                    handleArtworkImage(
                      item.thumbnail || item.primary_image,
                      item.name || item.title,
                      item.description,
                      item.file,
                      state.ArtworkImage
                    )
                  }
                  type={"white"}
                  key={item.id}
                  audioFile={item.file}
                  image={item.thumbnail || item.primary_image}
                  location={item.address}
                  name={item.artist_name}
                />
              ))}
          </div>
        </div>
      )}
      {(apiData.event_artist || apiData.artist) &&
        (apiData.event_artist.length > 0 || apiData.artist.length > 0) && (
          <div className="mb-5">
            <p className="artwork-title mt-4 ta-start mb-3">Featured Artists</p>
            <div className="w-100 display-direction-column ta-start">
              {apiData.event_artist &&
                apiData.event_artist
                  .slice(0, 3)
                  .map((item) => (
                    <ArtistsComponent
                      website={item.website}
                      key={item.id}
                      image={item.profile_pic}
                      location={item.short_address}
                      name={item.first_name + " " + item.last_name}
                    />
                  ))}
              {apiData.artist &&
                apiData.artist
                  .slice(0, 3)
                  .map((item) => (
                    <ArtistsComponent
                      website={item.website}
                      key={item.id}
                      image={item.profile_pic}
                      location={item.short_address}
                      name={item.first_name + " " + item.last_name}
                    />
                  ))}
            </div>
          </div>
        )}
      {apiData.art_work?.length > 0 && (
        <div className="mb-5">
          <p className="artwork-title ta-start mb-3">
            Featured Artworks of Artists
          </p>
          <div className="w-100 display-direction-row ta-start overflow-x-scroll">
            {apiData?.art_work &&
              apiData?.art_work.map((item) => (
                <div>
                  <ImageComponent
                    handleArtworkImage={() =>
                      handleArtworkImage(
                        item.thumbnail || item.primary_image,
                        item.name || item.title,
                        item.description,
                        item.file,
                        true
                      )
                    }
                    key={item.id}
                    url={item.thumbnail || item.primary_image}
                    title={item.title}
                  />
                  <p className="mt-2 artwork-name">{item.title}</p>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

function ArtworkComponent(props) {
  return (
    <div>
      <div
        className="display-direction-column pointer"
        onClick={() => props.handleArtworkImage()}
      >
        <div style={{ position: "relative" }}>
          {props.audioFile && (
            <div className="artwork-audio">
              <svg
                style={{ paddingLeft: "8px" }}
                width="30"
                height="30"
                viewBox="0 0 30 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0V40L30 20L0 0Z" fill="white" />
              </svg>
            </div>
          )}
          <img
            loading="lazy"
            className="artwork-img-container object-fit-image"
            src={props.image}
            alt="audio"
          />
        </div>
      </div>
    </div>
  );
}
function ImageComponent(props) {
  return (
    <div
      className="mt-20 mr-4 flex-direction-row pointer"
      onClick={() => props.handleArtworkImage()}
    >
      <img
        loading="lazy"
        className="image-container br--12 object-fit-image"
        src={props.url}
        alt="artwork"
      />
    </div>
  );
}
function ArtistsComponent(props) {
  return (
    <div className="display-direction-row mb-3">
      <a href={props?.website} target="_blank" rel="noreferrer">
        <img
          className="artist-img-container object-fit-image mr-3"
          src={props.image}
          alt="artist"
        />
      </a>
      <div className="display-direction-column">
        <p className="artist-name ta-start dot-3">{props.name}</p>
        {props.location && (
          <div className="display-flex mt--10 mr-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.75146 12.2656C4.84521 12.4297 5.00928 12.5 5.22021 12.5C5.40771 12.5 5.57178 12.4297 5.68896 12.2656L7.25928 10.0156C8.03271 8.89062 8.54834 8.14062 8.80615 7.74219C9.15771 7.17969 9.39209 6.71094 9.53271 6.33594C9.6499 5.96094 9.72021 5.51562 9.72021 5C9.72021 4.20312 9.50928 3.45312 9.11084 2.75C8.68896 2.07031 8.1499 1.53125 7.47021 1.10938C6.76709 0.710938 6.01709 0.5 5.22021 0.5C4.3999 0.5 3.6499 0.710938 2.97021 1.10938C2.26709 1.53125 1.72803 2.07031 1.32959 2.75C0.907715 3.45312 0.720215 4.20312 0.720215 5C0.720215 5.51562 0.76709 5.96094 0.907715 6.33594C1.0249 6.71094 1.25928 7.17969 1.63428 7.74219C1.86865 8.14062 2.38428 8.89062 3.18115 10.0156C3.81396 10.9297 4.32959 11.6797 4.75146 12.2656ZM5.22021 6.875C4.70459 6.875 4.25928 6.71094 3.88428 6.33594C3.50928 5.96094 3.34521 5.51562 3.34521 5C3.34521 4.48438 3.50928 4.0625 3.88428 3.6875C4.25928 3.3125 4.70459 3.125 5.22021 3.125C5.73584 3.125 6.15771 3.3125 6.53271 3.6875C6.90771 4.0625 7.09521 4.48438 7.09521 5C7.09521 5.51562 6.90771 5.96094 6.53271 6.33594C6.15771 6.71094 5.73584 6.875 5.22021 6.875Z"
                fill="#FB5959"
              />
            </svg>
            <p className="artists-location mb-2">{props.location}</p>
          </div>
        )}
        {props?.website && (
          <a href={props.website} target="_blank" rel="noreferrer">
            <div className="d-flex">
              <p className="view-website mb-0 mr-1 mt-1">Website</p>
              <img src={RedArrow} width="20px" alt="website" />
            </div>
          </a>
        )}
      </div>
    </div>
  );
}

export default EventCardDetails;
