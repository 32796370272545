import React, { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";
import { useHistory, useParams } from "react-router";
import {
  DEFAULT_BACK_IMAGE_URL,
  MAP_THEME_STYLES
} from "../Constants/Constant";
import LocationMarker from "../assets/icons/map_location.svg";
import MapMarker from "../assets/icons/map_marker.svg";
import "../components/DiscoverLocation.css";
import { Loader } from "../helpers/helpers";

function BaseMap(props) {
  let totalExhibitionItem = 0;
  let { filterType, id, cityId } = useParams();
  const getLatitudeAndLongitude = (isLat) => {
    return (
      props?.eventsData?.length > 0 &&
      props?.eventsData?.reduce((accumlator, currentObj) => {
        if (currentObj.type === "exhibition") {
          if (isLat === true) {
            totalExhibitionItem = totalExhibitionItem + 1;
            accumlator = accumlator + Number(currentObj.lat);
          } else {
            accumlator = accumlator + Number(currentObj.lon);
          }
        }
        return accumlator;
      }, 0)
    );
  };
  let latResult = getLatitudeAndLongitude(true);
  let longResult = getLatitudeAndLongitude(false);

  const handleLearnMore = () => {
    props.zoomIn();
    props.setLearnMore(true);
    props.setCurrentlyViewingLocationID(openInfoWindowMarkerId);
  };
  const getMapCenter = () => {
    if (props?.currentlyViewingLocationID) {
      try {
        const filteredLocation = eventsAndUserLocations.filter(
          (location) => location.id === props.currentlyViewingLocationID
        )[0];
        if (filteredLocation) {
          return {
            lat: Number(filteredLocation.lat),
            lng: Number(filteredLocation.lon),
          };
        }
      } catch (err) {
        console.log("error calculating map center", err);
      }
    }

    return {
      lat:
        latResult === 0
          ? Number(props.latitude)
          : Number(latResult / totalExhibitionItem),
      lng:
        longResult === 0
          ? Number(props.longitude)
          : Number(longResult / totalExhibitionItem),
    };
  };

  //merge current location object to map object
  const eventsAndUserLocations = [...props?.eventsData, ...props.location_data];
  const [openInfoWindowMarkerId, setopenInfoWindowMarkerId] = useState();
  // const [isOpen, setIsOpen] = useState(false);

  // const handleToggleClose = () => {
  //   setIsOpen(false);
  // };
  const handleSelectedTree = (tree_id) => {
    // setIsOpen(true);
    setopenInfoWindowMarkerId(tree_id);
  };

  let history = useHistory();

  return (
    <GoogleMap
      options={{
        styles: MAP_THEME_STYLES,
        fullscreenControl: false,
        zoomControl: false,
        autoPan: true,
        panControl: true,

        streetViewControl: false,
        keyboardShortcuts: false,
        gestureHandling: "greedy",
        mapTypeControlOptions: { mapTypeIds: [] },
      }}
      zoom={15}
      center={getMapCenter()}
      // defaultZoom={18}
    >
      {props.eventsDataloading ? (
        <Loader />
      ) : (
        eventsAndUserLocations.length > 0 &&
        eventsAndUserLocations.map((item, index) => {
          if (props.currentlyViewingLocationID) {
            if (props.currentlyViewingLocationID != item.id) {
              return null;
            }
          }

          return (
            <Marker
              icon={typeof item.id === undefined ? LocationMarker : MapMarker}
              key={index}
              position={{ lat: Number(item.lat), lng: Number(item.lon) }}
              onClick={() =>
                typeof item.id === undefined ? "" : handleSelectedTree(item.id)
              }
            >
              {handleLearnMore()}
              {/* {(openInfoWindowMarkerId === item.id || parseInt(cityId) === item.id) && ( */}
              {parseInt(cityId) === item.id && (
                <InfoWindow
                  disableAutoPan={true}
                  // onCloseClick={() => handleToggleClose()}
                >
                  <div
                    style={{ maxWidth: "280px" }}
                    onClick={() => {
                      handleLearnMore();
                      !cityId &&
                        history.push({
                          pathname: `${item.name.replaceAll("/", "_")}/${
                            item.id
                          }/${id}`,
                        });
                    }}
                    className="d-flex align-items-center"
                  >
                    <img
                      src={
                        item?.images?.length > 0
                          ? item.images[0].image_file
                          : DEFAULT_BACK_IMAGE_URL
                      }
                      style={{ borderRadius: "8px" }}
                      height="60px"
                      width="60px"
                      alt="info window"
                    />
                    <div className="d-flex-column ml-2">
                      <p className="mb-1 info-window-name dot-3">{item.name}</p>
                      <p className="info-window-date mb-0">{item.timings}</p>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })
      )}
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(BaseMap));

function Map(props) {
  return (
    <div className="h-100vh-map">
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        loadingElement={<div style={{ height: "100vh" }} />}
        containerElement={<div style={{ height: "100vh" }} />}
        mapElement={<div style={{ height: "105vh", overflow: "scroll" }} />}
        cid={props.cid}
        todayDate={props.todayDate}
        eid={props.eid}
        isCollapsible={props.isCollapsible}
        latitude={props.latitude}
        longitude={props.longitude}
        collective_slug_url={props.collective_slug_url}
        positions={props.position}
        zoomIn={props.zoomIn}
        setCurrentlyViewingLocationID={props.setCurrentlyViewingLocationID}
        zoomOut={props.zoomOut}
        isLearnMore={props.isLearnMore}
        setLearnMore={props.setLearnMore}
        onCustomClick={(id) => props.onCustomClick(id)}
        setSelectedTreeId={props.setSelectedTreeId}
        zoomLevel={props.zoomLevel}
        currentlyViewingLocationID={props.currentlyViewingLocationID}
        location_data={props.location_data}
        eventsData={props.eventsData}
        eventsDataloading={props.eventsDataloading}
      />
    </div>
  );
}

export default Map;
