import React, { useEffect, useState } from "react";
import "../components/DiscoverLocation.css";
import "../components/EventComponent.css";
import { withRouter } from "react-router-dom";
import ArtworkPopUp from "../components/ArtworkPopUp";
import ShareBtn from "../assets/icons/ShareBtn.svg";
import RedHeart from "../assets/icons/RedHeart.svg";
import RedArrow from "../assets/icons/RedArrow.svg";
import { EventCurrentId, EventData } from "../Constants/Constant";
import "../components/DiscoverLocation.css";
import WhiteHeart from "../assets/icons/Whiteheart.svg";
import GeocodeMap from "../components/GeocodeMap";
import { VrIcon } from "../assets/Icons";
function EventDetailScreen(props) {
  const data = props.eventDetails;
  const [isLearnMore, setLearnMore] = useState(true);
  const [isArtworkPresent, setisArtworkPresent] = useState(false);
  //   const audioArtwork = [...data.audiovideo, ...data.art_work];
  const handleLearnMore = () => {
    setLearnMore(!isLearnMore);
  };

  const [state, setState] = useState({
    ArtworkImage: "",
    ArtworkTitle: "",
    ArtworkDesc: "",
    ArtworkAudio: "",
  });

  const handleDateFormat = (timings, startDate, endDate) => {
    let date = timings.split(" ");
    if (startDate === endDate) {
      return date[3] + " " + date[4] + " " + date[5];
    }
    return date.join(" ");
  };

  const handleArtworkImage = (image, title, desc, audio, isArtworkPresent) => {
    setisArtworkPresent(isArtworkPresent);

    setState((prevState) => ({
      ...prevState,
      ArtworkImage: image,
      ArtworkTitle: title,
      ArtworkDesc: desc,
      ArtworkAudio: audio,
    }));
  };
  useEffect(() => {
    if (isLearnMore) {
      document.getElementById("list-container").scrollTo(0, 0);
    }
  }, []);
  return (
    <div className=" w-100 pad-15 overflow-y-scroll">
      {state.ArtworkImage && (
        <div className="w-50">
          <ArtworkPopUp
            imgArray={isArtworkPresent ? data.art_work : data.images}
            ArtworkAudio={state.ArtworkAudio}
            ArtworkTitle={state.ArtworkTitle}
            ArtworkDesc={state.ArtworkDesc}
            ArtworkImage={state.ArtworkImage}
            handleArtworkImage={handleArtworkImage}
          />
        </div>
      )}
      {/* <div onClick={() => handleBackButtonClick()}
                className="back-button-border">
                <p className="back-text">Back
                    <span style={{ marginLeft: '4px' }} ><svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z" fill="black" />
                    </svg>
                    </span></p>
            </div> */}
      <p className="event-title ta-center d-md-none font-weight-bold">
        Details
      </p>
      <div className="hide-scroll w-100 display-direction-row overflow-x-scroll  ta-start pointer">
        {data.images &&
          data.images.map((item) => (
            <ImageComponent
              key={item.id}
              url={item.image_file}
              handleArtworkImage={() => handleArtworkImage(item.image_file)}
            />
          ))}
      </div>
      <div className="w-100 display-direction-row mt-4 ">
        <div className="w-100 d-flex justify-content-between">
          <div className="w-60 d-flex">
            <p className="event-title ta-start mb-2">{data.name}</p>
          </div>
          {/* <div className='d-flex'>
            <img
              src={ShareBtn}
              height='22px'
              width='22px'
              className='mr-4 mt-1'
            />
            <img
              onClick={() => {
                setIsLiked(!isLiked);
              }}
              src={isLiked ? RedHeart : WhiteHeart}
              height='27px'
              width='27px'
            />
          </div> */}
        </div>
      </div>

      {isLearnMore ? (
        <div>
          <GeocodeMap
            eventAddress={data.address}
            lat={data.lat}
            lon={data.lon}
          />
          <p className="event-date ta-start mt-1">
            {" "}
            {handleDateFormat(data.timings, data.start_date, data.end_date)}
          </p>

          {/* <p className="event-desc ta-start f-size-12 mt--13">{'Tuesday-Sunday, 10 AM - 12 PM '}</p> */}
        </div>
      ) : (
        <p className="event-title ta-start">{data.address}</p>
      )}
      {data.description && (
        <>
          <p className="event-desc-heading mb-1 mt-4 ta-start">Description</p>
          <p
            className="event-desc ta-start mb-4"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </>
      )}
      {data?.website && (
        <a href={data.website} target="_blank" rel="noreferrer">
          <div className="d-flex">
            <p className="view-website mb-1 mr-1 mt-1">Learn more</p>
            <img src={RedArrow} width="20px" alt="data" />
          </div>
        </a>
      )}
      {/* Details Page */}
      {data?.ar_link && (
        <a href={data.ar_link} target="_blank" rel="noreferrer">
          <div className=" mt-3 mb-3 text-white view-ar-link">
            <VrIcon />
            <span className="ml-2">View AR</span>
          </div>
        </a>
      )}
      {data?.marketplace_url && (
        <a href={data.marketplace_url} target="_blank" rel="noreferrer">
          <div className="d-flex mt-3 mb-3">
            <p className="view-website mb-1 mr-1 mt-1">View On Marketplace</p>
            <img src={RedArrow} width="20px" alt="marketplace" />
          </div>
        </a>
      )}
      {isLearnMore && (
        <div>
          {data?.audiovideo && data?.audiovideo?.length > 0 && (
            <div className="mt-30">
              <p className="artwork-title ta-start mb-2">Hear More</p>
              <div className="w-100 display-direction-row ta-start">
                {data?.audiovideo &&
                  data?.audiovideo.map((item) => (
                    <ArtworkComponent
                      handleArtworkImage={() =>
                        handleArtworkImage(
                          item.thumbnail || item.primary_image,
                          item.name || item.title,
                          item.description,
                          item.file,
                          state.ArtworkImage
                        )
                      }
                      type={"white"}
                      key={item.id}
                      audioFile={item.file}
                      image={item.thumbnail || item.primary_image}
                      location={item.address}
                      name={item.artist_name}
                    />
                  ))}
              </div>
            </div>
          )}
          {data.artist && data.artist.length > 0 && (
            <div className="mb-5">
              <p className="artwork-title mt-4 ta-start mb-3">
                Featured Artists
              </p>
              <div className="w-100 display-direction-column ta-start">
                {data.artist &&
                  data.artist
                    .slice(0, 3)
                    .map((item) => (
                      <ArtistsComponent
                        website={item.website}
                        key={item.id}
                        image={item.profile_pic}
                        location={item.short_address}
                        name={item.first_name + " " + item.last_name}
                      />
                    ))}
              </div>
            </div>
          )}

          {data.art_work?.length > 0 && (
            <div className="mb-5">
              <p className="artwork-title ta-start mb-3">
                Featured Artworks of Artists
              </p>
              <div className="w-100 display-direction-row ta-start overflow-x-scroll">
                {data?.art_work &&
                  data?.art_work.map((item) => (
                    <div>
                      <ImageComponent
                        handleArtworkImage={() =>
                          handleArtworkImage(
                            item.thumbnail || item.primary_image,
                            item.name || item.title,
                            item.description,
                            item.file,
                            true
                          )
                        }
                        key={item.id}
                        url={item.thumbnail || item.primary_image}
                        title={item.title}
                      />
                      <p className="mt-2 artwork-name">{item.title}</p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
      {/* <p onClick={handleLearnMore} // TODO
                className="event-learn-more ta-start"
                style={{ marginTop: isLearnMore ? '20px' : '0px', cursor: 'pointer' }}>{isLearnMore ? 'Show Less >>' : 'Learn more >>'}</p> */}
    </div>
  );
}

function ImageComponent(props) {
  return (
    <div
      className="mt-20 mr-4 flex-direction-row pointer"
      onClick={() => props.handleArtworkImage()}
    >
      <img
        loading="lazy"
        className="image-container br--12 object-fit-image"
        src={props.url}
        alt="artwork"
      />
    </div>
  );
}

function ArtistsComponent(props) {
  return (
    <div className="display-direction-row mb-3">
      <a href={props?.website} target="_blank" rel="noreferrer">
        <img
          className="artist-img-container object-fit-image mr-3"
          src={props.image}
          alt="artist"
        />
      </a>
      <div className="display-direction-column">
        <p className="artist-name ta-start dot-3">{props.name}</p>
        {props.location && (
          <div className="display-flex mt--10 mr-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.75146 12.2656C4.84521 12.4297 5.00928 12.5 5.22021 12.5C5.40771 12.5 5.57178 12.4297 5.68896 12.2656L7.25928 10.0156C8.03271 8.89062 8.54834 8.14062 8.80615 7.74219C9.15771 7.17969 9.39209 6.71094 9.53271 6.33594C9.6499 5.96094 9.72021 5.51562 9.72021 5C9.72021 4.20312 9.50928 3.45312 9.11084 2.75C8.68896 2.07031 8.1499 1.53125 7.47021 1.10938C6.76709 0.710938 6.01709 0.5 5.22021 0.5C4.3999 0.5 3.6499 0.710938 2.97021 1.10938C2.26709 1.53125 1.72803 2.07031 1.32959 2.75C0.907715 3.45312 0.720215 4.20312 0.720215 5C0.720215 5.51562 0.76709 5.96094 0.907715 6.33594C1.0249 6.71094 1.25928 7.17969 1.63428 7.74219C1.86865 8.14062 2.38428 8.89062 3.18115 10.0156C3.81396 10.9297 4.32959 11.6797 4.75146 12.2656ZM5.22021 6.875C4.70459 6.875 4.25928 6.71094 3.88428 6.33594C3.50928 5.96094 3.34521 5.51562 3.34521 5C3.34521 4.48438 3.50928 4.0625 3.88428 3.6875C4.25928 3.3125 4.70459 3.125 5.22021 3.125C5.73584 3.125 6.15771 3.3125 6.53271 3.6875C6.90771 4.0625 7.09521 4.48438 7.09521 5C7.09521 5.51562 6.90771 5.96094 6.53271 6.33594C6.15771 6.71094 5.73584 6.875 5.22021 6.875Z"
                fill="#FB5959"
              />
            </svg>
            <p className="artists-location mb-2">{props.location}</p>
          </div>
        )}
        {props?.website && (
          <a href={props.website} target="_blank" rel="noreferrer">
            <div className="d-flex">
              <p className="view-website mb-0 mr-1 mt-1">Website</p>
              <img src={RedArrow} width="20px" alt="website" />
            </div>
          </a>
        )}
      </div>
    </div>
  );
}

function ArtworkComponent(props) {
  return (
    <div>
      <div
        className="display-direction-column"
        onClick={() => props.handleArtworkImage()}
      >
        <div style={{ position: "relative" }}>
          {props.audioFile && (
            <div className="artwork-audio">
              <svg
                style={{ paddingLeft: "8px" }}
                width="30"
                height="30"
                viewBox="0 0 30 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0V40L30 20L0 0Z" fill="white" />
              </svg>
            </div>
          )}
          <img
            loading="lazy"
            className="artwork-img-container object-fit-image"
            src={props.image}
            alt="audio"
          />
        </div>
      </div>
    </div>
  );
}
export default withRouter(EventDetailScreen);
