import React, { useState } from 'react';
import "../App.css";
import "../components/DiscoverLocation.css";
import Map from "../components/MapComponent";

const ArtEventMap = (props) => {
    const [mapZoomLevel, setMapZoomLevel] = useState(8);

    return (
        <Map
            cid={props.cid}
            eventsData={props.eventsData}
            eventsDataloading={props.eventsDataloading}
            eid={props.eid}
            todayDate={props.todayDate}
            latitude={props.latitude}
            isLearnMore={props.isLearnMore}
            setLearnMore={props.setLearnMore}
            setCurrentlyViewingLocationID={(id) =>
                props.setCurrentlyViewingLocationID(id)
            }
            zoomIn={(_) => setMapZoomLevel(19)}
            zoomOut={(_) => setMapZoomLevel(12)}
            longitude={props.longitude}
            isCollapsible={props.isCollapsible}
            handleCurrentLocation={props.handleCurrentLocation}
            collective_slug_url={props.collective_slug_url}
            ArtworkAudio={props.ArtworkAudio}
            ArtworkTitle={props.ArtworkTitle}
            ArtworkDesc={props.ArtworkDesc}
            ArtworkImage={props.ArtworkImage}
            handleArtworkImage={props.handleArtworkImage}
            isDiscoverLocation={props.isDiscoverLocation}
            handleDiscoverLocation={props.handleDiscoverLocation}
            onCustomClick={(id) => props.setEid(id)}
            handleFullScreenImage={props.handleFullScreenImage}
            setEid={props.setEid}
            zoomLevel={mapZoomLevel}
            setSelectedTreeId={props.setSelectedTreeId}
            currentlyViewingLocationID={props.currentlyViewingLocationID}
            location_data={props.eventsData}
        />
    )
}

export default ArtEventMap