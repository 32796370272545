import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import { uploadToS3 } from '../../api/apihelpers';
import { Loader } from '../../helpers/helpers';

import './AddArtist.css';
import ImageUploader from './ImageUploader';
import TextError from './TextError';

function AddArtistForm(props) {
  const [validated, setValidated] = useState(false);
  const [isValidWebsite, setIsValidWebsite] = useState(false);
  const [addArtistBtn, setAddArtistBtn] = useState(false);

  useEffect(() => {
    props.setProfileImage();
  }, [])
  
  const urlPatternValidation = URL => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    return regex.test(URL);
  };
  const handleArtistDetailsChange = (e) => {
    const { id, value } = e.target;
    if(id==="website"){

      setIsValidWebsite(urlPatternValidation(value));
    }
    props.setNewArtistDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    props.setError('');
  };
  const handleSubmit = async (event) => {
    // const form = event.currentTarget;
    setAddArtistBtn(true);
    event.preventDefault();
    if (!isValidWebsite) {
      setValidated(true);
      event.stopPropagation();
    } else {
      const payload = {
        first_name: props?.newArtistDetails?.first_name,
        last_name: props?.newArtistDetails?.last_name,
        short_address: props?.newArtistDetails?.address,
        website: props?.newArtistDetails?.website,
      };

      if (props.profileImage) {
        // User added a image
        props.setLoading(true);
        uploadToS3(props.profileImage)
          .then((imageUrl) => {
            // Adding Image to payload
            payload.profile_pic = imageUrl;
            props.handleCreateArtist(payload);
          })
          .catch((err) => {
            console.warn('Error uploading Image to S3', err);
            props.setError(err);
          })
          .finally((_) => props.setLoading(false));
      } else {
        // No Image was added
        props.handleCreateArtist(payload);
      }
    }
  };
 
  return (
    <>
      {props.loading ? (
        <Loader />
      ) : (
        <Row>
          <Col>
          {!props.profileImage && <h5 className='text-center lime'>Upload Image</h5>}
          </Col>
          <Col md={12} className='d-flex justify-content-center'>
            <ImageUploader
              uploadImageBtnClass='upload-artist-image-btn'
              imageFileSetter={(file) => props.setProfileImage(file)}
              isChangeImage
              humanFace
            />
          </Col>
          <Col sm={12}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label>Artist First Name</Form.Label>
                <input
                  type='text'
                  placeholder='Artist First Name'
                  className='form-event-input'
                  required
                  id='first_name'
                  value={props?.newArtistDetails?.first_name}
                  onChange={(e) => handleArtistDetailsChange(e)}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a First Name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Artist Last Name</Form.Label>
                <input
                  type='text'
                  placeholder='Artist Last Name'
                  className='form-event-input'
                  required
                  id='last_name'
                  value={props?.newArtistDetails?.last_name}
                  onChange={(e) => handleArtistDetailsChange(e)}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a Last Name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>Website</Form.Label>
                <input
                  className='form-event-input'
                  type='url'
                  placeholder='Artist Website'
                  id='website'
                  required
                  value={props?.newArtistDetails?.website}
                  onChange={(e) => handleArtistDetailsChange(e)}
                />
                {!isValidWebsite  && addArtistBtn && <TextError  children="Please provide a valid URL." />}
                {/* <Form.Control.Feedback type='invalid'>
                  Please provide a valid URL.
                </Form.Control.Feedback> */}
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Address</Form.Label>
                <input
                  className='form-event-input'
                  type='text'
                  placeholder='Artist Address'
                  id='address'
                  required
                  value={props?.newArtistDetails?.address}
                  onChange={(e) => handleArtistDetailsChange(e)}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide an address.
                </Form.Control.Feedback>
              </Form.Group>
              <Row className='mt-40 d-sm-flex flex-wrap-reverse'>
                <TextError children={props.error} />
                <Col sm={6}>
                  <button
                    onClick={() => props.handleClose()}
                    type='button'
                    className='outline-black-button  btn-block '
                  >
                    Cancel
                  </button>
                </Col>
                
                <Col sm={6}>
                  {' '}
                  <button
                    type='submit'
                    className='lime-btn-with-blue-text border-0 btn-block myClass'
                  >
                    Add Artist
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
}
export default AddArtistForm;
