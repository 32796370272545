import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import EventDays from '../EventDays';

import './AddArtist.css';
import AddArtistForm from './AddArtistForm';

function FormModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      centered
      dialogClassName='modal-styling'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className='NY_Font--md'>{props.title}</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.isArtistModal && (
          <AddArtistForm
            handleCreateArtist={props.handleCreateArtist}
            profileImage={props.profileImage}
            error={props.error}
            loading={props.loading}
            setLoading={props.setLoading}
            setError={props.setError}
            handleClose={props.handleClose}
            newArtistDetails={props.newArtistDetails}
            setNewArtistDetails={props.setNewArtistDetails}
            setProfileImage={props.setProfileImage}
          />
        )}
        {props.isWeekDayModal && (
          <EventDays
            selectedDays={props.selectedDays}
            setSelectedDays={props.setSelectedDays}
          />
        )}
        {props.popUpInfo && (
          <p className='info-text'>{props.popUpInfo}</p>
        )}
        {props.ButtonsInPopUp && (
          <Row>
            <Col>
            <button className='outline-button mt-2 btn-block' style={{padding: "12px 20px"}} onClick={props.handleClose}>Back to Discover</button>
            </Col>
            <Col>
            <button className='popUpBtn border-0 mt-2 btn-block' style={{padding: "12px 0px"}} onClick={()=> window.location.reload()}>Submit Another Event</button>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}
export default FormModal;
