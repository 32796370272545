import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";
import { useHistory, useParams, useLocation } from "react-router";
import {
  DEFAULT_BACK_IMAGE_URL,
  MAP_THEME_STYLES,
  screenWidth,
} from "../Constants/Constant";
import MapMarker from "../assets/icons/map_marker.svg";
import "../components/DiscoverLocation.css";
import { Loader } from "../helpers/helpers";
import ArtworkPopUp from "./ArtworkPopUp";
import "./Map.css";
import useQuery from '../hooks/useQuery';

function BaseMap(props) {
  const ref = useRef(null);

  let query = useQuery();
  const eventId = query.get('eventId')

  let totalExhibitionItem = 0;
  let { filterType, id, cityName, cityId } = useParams();
  const pastevent =
    filterType === "past-events" || filterType === "city-past-events";

  const todayDate = new Date().setHours(0, 0, 0, 0);
  const getLatitudeAndLongitude = (isLat) => {
    return (
      props?.eventsData?.length > 0 &&
      props?.eventsData?.reduce((accumlator, currentObj) => {
        if (currentObj.type === "exhibition") {
          if (isLat === true) {
            totalExhibitionItem = totalExhibitionItem + 1;
            accumlator = accumlator + Number(currentObj.lat);
          } else {
            accumlator = accumlator + Number(currentObj.lon);
          }
        }
        return accumlator;
      }, 0)
    );
  };
  let latResult = getLatitudeAndLongitude(true);
  let longResult = getLatitudeAndLongitude(false);

  const getMapCenter = () => {
    if (props?.currentlyViewingLocationID) {
      try {
        const filteredLocation = eventsAndUserLocations.filter(
          (location) => location.id === props.currentlyViewingLocationID
        )[0];
        if (filteredLocation) {
          return {
            lat: Number(filteredLocation.lat),
            lng: Number(filteredLocation.lon),
          };
        }
      } catch (err) {
        console.log("error calculating map center", err);
      }
    }
    return {
      lat:
        latResult === 0
          ? Number(props.latitude)
          : Number(latResult / totalExhibitionItem) +
            (screenWidth > 768 ? -0.01 : -0.1),
      lng:
        longResult === 0
          ? Number(props.longitude)
          : Number(longResult / totalExhibitionItem),
    };
  };
  //merge current location object to map object
  const eventsAndUserLocations = [...props?.eventsData, ...props.location_data];
  const [openInfoWindowMarkerId, setopenInfoWindowMarkerId] = useState();
  const [allCardsAddressIsSame, setAllCardsAddressIsSame] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);

  // const handleToggleClose = () => {
  //   setIsOpen(false);
  // };
  const handleSelectedTree = (tree_id) => {
    // setIsOpen(true);
    setopenInfoWindowMarkerId(tree_id);
  };

  let history = useHistory();
  const location = useLocation();

  const fitBounds = useCallback(() => {
    const bounds = new window.google.maps.LatLngBounds();
    props.eventsData.map((item) => {
      if(item.type === 'exhibition') {
      // TODO: Fix this logic
      // if (
      //   pastevent
      //     ? new Date(item.end_date).setHours(0, 0, 0, 0) < todayDate
      //     : new Date(item.end_date).setHours(0, 0, 0, 0) > todayDate
      // )
      bounds.extend({ lat: Number(item.lat), lng: Number(item.lon) });
      }
    });
    ref.current.fitBounds(bounds);
  }, [pastevent, props.eventsData, todayDate]);

  // // Fit bounds on mount, and when the markers change

  useEffect(() => {
    !allCardsAddressIsSame && fitBounds();
  }, [allCardsAddressIsSame, fitBounds]);

  useEffect(() => {
    const latArray = [];
    const lonArray = [];
    props.eventsData.map((item) => {
      return latArray.push(item.lat), lonArray.push(item.lon);
    });
    //.every will check all lat value in latarray are same or not
    const allLatEqual = (lat) => latArray.every((val) => val === lat[0]);
    //alllatEqual will give a boolean value
    if (allLatEqual(latArray)) {
      const allLonEqual = (lon) => lonArray.every((val) => val === lon[0]);
      lonArray.length > 0 && setAllCardsAddressIsSame(allLonEqual(lonArray));
    }
  }, [props.eventsData]);

  return (
    <GoogleMap
      ref={ref}
      options={{
        styles: MAP_THEME_STYLES,
        fullscreenControl: false,
        zoomControl: false,
        autoPan: true,
        panControl: true,

        streetViewControl: false,
        keyboardShortcuts: false,
        gestureHandling: "greedy",
        mapTypeControlOptions: { mapTypeIds: [] },
      }}
      center={
        allCardsAddressIsSame && {
          lat: Number(props?.eventsData[0]?.lat),
          lng: Number(props?.eventsData[0]?.lon),
        }
      }
      zoom={allCardsAddressIsSame && 18}
    >
      {props.eventsDataloading ? (
        <Loader />
      ) : (
        props?.eventsData.length > 0 &&
        props?.eventsData.map((item, index) => {
          if(item.type === 'exhibition') {

            if (props.currentlyViewingLocationID) {
              if (props.currentlyViewingLocationID != item.id) {
                return null;
              }
            }
            // if (
            //   pastevent
            //     ? new Date(item.end_date).setHours(0, 0, 0, 0) <
            //     new Date().setHours(0, 0, 0, 0)
            //     : new Date(item.end_date).setHours(0, 0, 0, 0) >=
            //     new Date().setHours(0, 0, 0, 0)
            // )
            return (
              <Marker
                icon={MapMarker}
                key={index}
                position={{ lat: Number(item.lat), lng: Number(item.lon) }}
                onClick={() =>{
                  // eslint-disable-next-line no-unused-expressions
                  location.pathname === "/2023-AiOP-DRESS"
                  ?   history.push(location.pathname + "/" + item.id)
                  : typeof item.id === undefined
                  ? ""
                  : handleSelectedTree(item.id) ||
                    history.push({
                      pathname: `/${filterType}/${cityName}/${id}/${item.id}/${item.Page_no}`,
                    })
                  }
                }
              >
                {(openInfoWindowMarkerId === item.id ||
                  parseInt(cityId) === item.id || parseInt(eventId) === item.id)  && (
                  <InfoWindow disableAutoPan={true}>
                    <div
                      style={{ maxWidth: "280px" }}
                      onClick={() => {
                       
                        history.push({
                          pathname: `/${filterType}/${cityName}/${id}/${item.name.replaceAll(
                            "/",
                            "_"
                          )}/${item.id}/${item.Page_no}`,
                        });
                      }}
                      className="d-flex align-items-center pointer"
                    >
                      <img
                        src={
                          item?.images?.length > 0
                            ? item.images[0].image_file
                            : DEFAULT_BACK_IMAGE_URL
                        }
                        style={{ borderRadius: "8px" }}
                        height="60px"
                        width="60px"
                        alt="info window"
                      />
                      <div className="d-flex-column ml-2">
                        <p className="mb-1 info-window-name dot-3">{item.name}</p>
                        <p className="info-window-date mb-0">{item.timings}</p>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            );
          }
        })
      )}
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(BaseMap));

function Map(props) {
  return (
    <div className="h-100vh-map">
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        loadingElement={<div style={{ height: "100vh" }} />}
        containerElement={<div style={{ height: "100vh" }} />}
        mapElement={<div style={{ height: "105vh", overflow: "scroll" }} />}
        cid={props.cid}
        todayDate={props.todayDate}
        eid={props.eid}
        isCollapsible={props.isCollapsible}
        latitude={props.latitude}
        longitude={props.longitude}
        collective_slug_url={props.collective_slug_url}
        positions={props.position}
        zoomIn={props.zoomIn}
        setCurrentlyViewingLocationID={props.setCurrentlyViewingLocationID}
        zoomOut={props.zoomOut}
        isLearnMore={props.isLearnMore}
        setLearnMore={props.setLearnMore}
        onCustomClick={(id) => props.onCustomClick(id)}
        setSelectedTreeId={props.setSelectedTreeId}
        zoomLevel={props.zoomLevel}
        currentlyViewingLocationID={props.currentlyViewingLocationID}
        location_data={props.location_data}
        eventsData={props.eventsData}
        eventsDataloading={props.eventsDataloading}
      />

      {props.isArtworkImage && (
        <div>
          <ArtworkPopUp
            isArtworkAudio={props.isArtworkAudio}
            isArtworkTitle={props.isArtworkTitle}
            isArtworkDesc={props.isArtworkDesc}
            isArtworkImage={props.isArtworkImage}
            handleArtworkImage={props.handleArtworkImage}
          />
        </div>
      )}
      {props.eid > 0 && (
        <div
          style={{
            width: "62%",
            justifyContent: "end",
            position: "absolute",
            zIndex: 10,
            bottom: 20,
            right: 10,
            backgroundColor: "white",
          }}
        ></div>
      )}
    </div>
  );
}

export default Map;
