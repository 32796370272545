import React from "react";
import NRVLD from "../assets/images/NYFWxWeb3-NRVLD_CROWNVNV2.png";
import "./NRVLDBrandsXWeb3.css"

function NRVLDBrandsXWeb3() {
  return (
    <div className="mainNRVLD">
      <img src={NRVLD} className="NRVLDImg" alt="NRVLD-Brands-X-Web3" />
    </div>
  );
}

export default NRVLDBrandsXWeb3;
