export const DEFAULT_IMAGE_URL =
  "https://pollinate-web-static-dev.s3.amazonaws.com/media/public/HOME-Introducing-TFAC.jpg";
export const DEFAULT_BACK_IMAGE_URL =
  "https://images.unsplash.com/photo-1514483127413-f72f273478c3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80";
export const DEFAULT_AUDIO_URL = "http://streaming.tdiradio.com:8000/house.mp3";
export const DEFAULT_TIMER = 3000;
export const LATITUDE = "40.7492094";
export const CONNECTION_ERROR = "Please check internet connection";
export const LONGITUDE = "-73.9815265";
export const WEB_SITE_URL = "https://pollinate.co/event/#upcoming-accordian-2";
export const EventData = "data";
export const EventCurrentId = "currentId";
export const screenWidth =
  document.documentElement.clientWidth || window.innerWidth;
export const DaysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const todayDate = new Date();

export const ArtsMedium = [
  { label: "Pencil", value: "pencil" },
  { label: "Charcoal", value: "charcoal" },
  { label: "Digital", value: "digital" },
  { label: "Pastel", value: "pastel" },
  { label: "Ink", value: "ink" },
  { label: "Oil", value: "oil" },
  { label: "Acrylic", value: "acrylic" },
  { label: "Photography", value: "photography" },
  { label: "Sculpture", value: "sculpture" },
  { label: "Mixed Media", value: "mixed_media" },
];
export const HomePageTabs = [
  { value: "cities", label: "Cities" },
  { value: "featured", label: "Featured" },
];
export const OrganizationProfilePageTabs = [
  { value: "upcomingEvents", label: "Upcoming events" },
  { value: "allEvents", label: "All Events" },
];
export const MAP_THEME_STYLES = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];
