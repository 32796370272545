import ClaraFrancesca from "../assets/images/ClaraFrancesca.jpg";
import KatiePeytonHofstadter from "../assets/images/KatiePeytonHofstadter.jpg";
import AnneWichmann from "../assets/images/AnneWichmann.jpg";
import StateOfWomen from "../assets/images/StateOfWomen.png";
import StudentLoan from "../assets/images/StudentLoanDebt.png";
import WeHaveHerBack from "../assets/images/WeHaveHerBack.png";

export const DEFAULT_IMAGE_URL =
  "https://pollinate-web-static-dev.s3.amazonaws.com/media/public/HOME-Introducing-TFAC.jpg";
export const DEFAULT_BACK_IMAGE_URL =
  "https://images.unsplash.com/photo-1514483127413-f72f273478c3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80";
export const DEFAULT_AUDIO_URL = "http://streaming.tdiradio.com:8000/house.mp3";
export const DEFAULT_TIMER = 3000;
export const LATITUDE = "40.7492094";
export const LONGITUDE = "-73.9815265";
export const WEB_SITE_URL = "https://pollinate.co/event/#upcoming-accordian-2";
export const EventData = "data";
export const makeUsVisibleFAQ = [
  { heading: "Release Date -", info: "1st Of March" },
  { heading: "Total number of tokens -", info: "2022" },
  {
    heading: "Tokens withheld from sale -",
    info: "40 for participating artists and team",
  },
  { heading: "Token Type -", info: "ERC-1155" },
  { heading: "Blockchain -", info: "Polygon" },
  { heading: "File hosting -", info: "IPFS" },
  { heading: "Price -", info: "175 Matic" },
];
export const UnitedStateOfWomen = [
  {
    image: StateOfWomen,
    name: "Mr. Backlash (Angela Davis)",
    artist: " by Erin Ko",
    paired_with: "State of Women",
    ar_link: " https://www.instagram.com/ar/485427546526868/",
    description:
      " The gender equity movement has a unified message to Congress: #WeDemandMore. The United State of Women and over 50 gender justice organizations are calling on Congress to deliver on 5 critical policies women need to survive and thrive. Read and sign our letter to Congress.  ",
    take_action_btn_url: "https://usow.org/stateofwomen/",
  },
  {
    image: WeHaveHerBack,
    artist: " by Lauren Owen",

    paired_with: "#WeHaveHerBack",
    ar_link:
      "https://app.vectary.com/viewer/v1/?model=cfe08cd6-fdaa-48a3-9f99-e955bca7b132&env=studio3&turntable=3",
    name: "Excluded: Black Women in American History",
    description:
      "Of the 115 people who served on the Supreme Court, only 3 have been people of color — and only 5 have been women. Tell your Senators to support Judge Jackson’s nomination and bring the court one step closer to representing our diversity. ",

    take_action_btn_url: "https://usow.org/confirm/",
  },
  {
    image: StudentLoan,
    artist: " by Ansh Kumar",

    paired_with: "Student Loan Debt is a Gender Issue",
    name: "Colours of Kalpana",
    ar_link: "https://www.instagram.com/ar/492940468909258/",
    description:
      "Did you know that two-thirds of all student loan debt is held by women? Join the United State of Women and sign our petition calling on President Biden to cancel $50,000 of federal student loan debt for every borrower. Its past time to cancel the debt that holds women back.",
    take_action_btn_url: "https://usow.org/studentloans/#action",
  },
];
export const MakeUsVisibleTeamData = [
  {
    image: AnneWichmann,
    name: "Anne Wichmann",
    bio: "Concept & Curator & Organizer & Audio-tour",
    description:
      "Anne Wichmann aka She's Excited! is a media artist, musician, producer and powerhouse performer based in NYC. Her lifelong love and commitment to interdisciplinary arts and music show in her augmented solo compositions, her exploration of producing music in VR and her many collaborations with artist from different fields. As curator and program manager she wants to build bridges, open conversations and provide a platform for artists from diverse communities.",
    instagram_handle: "@shes_excited",
    instagram_url: "https://www.instagram.com/shes_excited/",
  },

  {
    image: KatiePeytonHofstadter,
    name: "Katie Peyton Hofstadter",
    bio: "Curator & Organizer",
    description:
      "Katie Peyton Hofstadter is an LA- and Brooklyn-based writer, artist and curator, and she’s excited to #MakeUsVisible. Her work been published in The Believer, BOMB, Gargoyle, Pank, the Vienna Biennale for Change, and Right Click Save, and she is a co-creator of the Climate Clock monument in New York.  She designed and led the Future Art Models program for apexart, guiding young creatives to experiment with alternate professional models in the arts. She teaches at Parsons, The New School, and lives with a dog named Peanut in a room full of books.",
    instagram_handle: "@katie_peyton_",
    instagram_url: "https://www.instagram.com/katie_peyton_/",
  },
  {
    image: ClaraFrancesca,
    name: "Clara Francesca",
    bio: "Curator & Organizer & Audio-tour",
    description:
      "Clara Francesca is the Philosopher of the Heart making Art. An award-winning artist, director, & speech coach, Clara's passion is unlocking the individual's authentic voice with a genuine curiosity for investigating what it truly means to co-exist in such a diverse world. Clara’s practice has always been driven by a sense of responsibility to sustainability and a deep dedication to conscionable conduct through equitable work practices, and final pieces of art. Photo: Melissa Segal",
    instagram_handle: "@clar_esca",
    instagram_url: "https://www.instagram.com/clar_esca/",
  },
];
export const EventCurrentId = "currentId";
export const HomePageTabs = [
  // { value: "cities", label: "Cities" },

  { value: "featured", label: "Current & Upcoming" },
  { value: "past-events", label: "Past" },
];
export const screenWidth =
  document.documentElement.clientWidth || window.innerWidth;
export const MAP_THEME_STYLES = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];

export const EVENT_DETAIL_END_DATE = (eventDetail) => new Date(eventDetail.end_date).setHours(0, 0, 0, 0);
export const TODAY_DATE = new Date().setHours(0, 0, 0, 0);
